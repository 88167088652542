import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import Home from './pages/Home';
import BulkQuery from './pages/BulkQuery';
import Settings from './pages/Settings';
import Login from './pages/Login'
import FourOFour from './pages/FourOFour';
import { LicenseInfo } from '@mui/x-data-grid-pro';
import { AuthContextProvider } from './store/auth-context';
import AuthContext from './store/auth-context';
import { useContext } from 'react';

LicenseInfo.setLicenseKey(
  '5d9caded4592e03feb1c07e8b8816fd3T1JERVI6MzcyNzcsRVhQSVJZPTE2NzU2MTUxMjUwMDAsS0VZVkVSU0lPTj0x',
);

function RouterContextHandler() {
  const authCtx = useContext(AuthContext);

  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<App />}>
          {authCtx.isLoggedIn && (<Route path='home' element={<Home />} />)}
          {authCtx.isLoggedIn && (<Route path='bulkQuery' element={<BulkQuery />} />)}
          {authCtx.isLoggedIn && (<Route path='settings' element={<Settings />} />)}
          <Route path='login' element={<Login />} />
          <Route
            path="*"
            element={<FourOFour />}
          // element={
          //   <main style={{ padding: "1rem" }}>
          //     <p>There's nothing here!</p>
          //   </main>
          // }
          />
        </Route>
      </Routes>
    </BrowserRouter>
  )
}


ReactDOM.render(
  <React.StrictMode>
    <AuthContextProvider>
      <RouterContextHandler />
    </AuthContextProvider>
  </React.StrictMode >,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
