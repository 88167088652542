import * as React from 'react';
import { useEffect, useState, useContext } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import { Fragment } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useLocation } from 'react-router-dom'
import TemporaryDrawer from './TemporaryDrawer';
import Drawer from '@mui/material/Drawer';
import Divider from '@mui/material/Divider';
import AuthContext from '../store/auth-context';


export default function NavBar() {

  // Authentication State
  const authCtx = useContext(AuthContext);
  const isLoggedIn = authCtx.isLoggedIn;
  const logout = authCtx.logout;

  // Handle Drawer State
  const [drawerOpen, setDrawerOpen] = useState(false);
  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };

  const [pageTitle, setPageTitle] = useState(null);

  const location = useLocation();

  function HeaderView() {
    //// console.log(location.pathname); // DEBUG
    switch (location.pathname) {
      case "/home":
        setPageTitle("Home");
        break;
      case "/bulkQuery":
        setPageTitle("Bulk Query");
        break;
      case "/settings":
        setPageTitle("Settings");
        break;
      case "/login":
        setPageTitle(""); // Do not show
        break;
    }
  }

  useEffect(() => {
    HeaderView();
  }, [location])

  const drawerIcon = <IconButton
    size="large"
    edge="start"
    color="inherit"
    aria-label="menu"
    sx={{ mr: 2 }}
    onClick={toggleDrawer}
  >
    <MenuIcon />
  </IconButton>

  const drawerWidth = 240;

  return (
    <Fragment>
      <Box sx={{ flexGrow: 1 }}>
        <AppBar position="static" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
          <Toolbar>
            {isLoggedIn && (drawerIcon)}
            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
              {isLoggedIn && (pageTitle)}
            </Typography>
            <Button component={RouterLink} to="/login" color="inherit" onClick={logout}>{!!isLoggedIn ? 'Logout' : ''}</Button>
          </Toolbar>
        </AppBar>
      </Box>

      <TemporaryDrawer state={drawerOpen} toggleDrawer={toggleDrawer} />
    </Fragment>
  );
}