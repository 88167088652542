import React, { useRef, useEffect, useState, Fragment } from "react";
import Box from '@mui/material/Box';
import { Container } from "@mui/material";
import Drawer from '@mui/material/Drawer';
import { Button, Stack } from '@mui/material';
import { Divider } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';
import { Typography } from "@mui/material";
import { currencyUSD, toTitleCase } from "../utilities/utilities"

import CoveragesTable from './quoteDetails/CoveragesTable';
import QuotesDataGrid from "./mapDrawer/QuotesDataGrid";

function MapDrawerContents__loading() {
  return (
    <Box sx={{
      padding: 1
    }}>
      <Stack spacing={2}>
        <Stack spacing={1}>
          <Skeleton variant="rectangular" width={120} height={30} />
          <Skeleton variant="rectangular" width={280} height={20} />
        </Stack>
        <Divider />
        <Skeleton variant="rectangular" height={80} />
        <Stack direction="row" spacing={2}>
          <Skeleton variant="rectangular" width={210} height={40} />
          <Skeleton variant="rectangular" width={210} height={40} />
          <Skeleton variant="rectangular" width={210} height={40} />
        </Stack>
        <Skeleton variant="rectangular" height={300} />
      </Stack >
    </Box>
  );
}

export default function MapDrawer(props) {

  function isDrawerOpen() {
    console.log(props.state);
  }

  const drawerWidth = 400;

  const [mapDrawerContents, setMapDrawerContents] = useState(MapDrawerContents__loading);

  useEffect(() => {
    if (props.quoteResult !== null) {
      setMapDrawerContents(<Fragment>
        <Box sx={{ padding: 2 }}>
          <Typography variant="h6">{toTitleCase(props.mapResult.SITE_ADDR)}</Typography>
          <Typography variant="body2">{toTitleCase(props.mapResult.SITE_ADDR)}, {toTitleCase(props.mapResult.CITY)} MA {props.mapResult.ZIP}</Typography>
        </Box>
        <Divider />
        <Box sx={{ padding: 2 }}>
          <Box sx={{ margin: 1, textAlign: 'center' }}>
            <Typography variant="h5">{currencyUSD(props.quoteResult['mostCompetitive']['premium'])}</Typography>
            <Typography variant="body2">Most Competitive Instant Quote</Typography>
          </Box>
          <Stack spacing={2} direction="row" sx={{ marginTop: 2 }}>
            <Button variant="contained" onClick={isDrawerOpen}>Request Info</Button>
            <Button variant="contained">Call Agent</Button>
            <Button variant="contained">Share</Button>
          </Stack>
          <Box sx={{
            marginTop: 2
          }}>
            <QuotesDataGrid quoteResult={props.quoteResult} />
          </Box>
        </Box>
      </Fragment >);
    } else {
      setMapDrawerContents(
        MapDrawerContents__loading
      );
    }
  }, [props.quoteResult]);

  return (
    <Drawer
      anchor={'left'}
      open={props.state}
      onClose={props.toggleDrawer}
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box' },
      }}
      PaperProps={{ style: { position: 'absolute' } }}
      BackdropProps={{ style: { position: 'absolute' } }}
      ModalProps={{
        container: document.getElementById('mapDrawer-container'),
        style: { position: 'absolute' }
      }}
    >
      {mapDrawerContents}
    </Drawer >
  );
}