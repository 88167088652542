import * as React from 'react';
import { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';

import { ajax, domain } from '../../utilities/utilities';

import Search from './bulkQueryComponent/Search';
import Filter from './bulkQueryComponent/Filter'
import MailingList from './bulkQueryComponent/MailingList';
import Quote from './bulkQueryComponent/Quote';


const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    '&:not(:last-child)': {
        borderBottom: 0,
    },
    '&:before': {
        display: 'none',
    },
}));

const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
        expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
        {...props}
    />
))(({ theme }) => ({
    backgroundColor:
        theme.palette.mode === 'dark'
            ? 'rgba(255, 255, 255, .05)'
            : 'rgba(0, 0, 0, .03)',
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
        marginLeft: theme.spacing(1),
    },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: '1px solid rgba(0, 0, 0, .125)',
}));


//
// EXPORT
//
export default function BulkQueryComponent(props) {

    // Default Expanded Accordian
    const [expanded, setExpanded] = React.useState('panel1');

    // Change Accordian Expansion
    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };

    // -----------
    async function fetch_multiSelectOptions(inputName) {

        // Timer Function
        var start_time = new Date().getTime();
        function getRquestSuccessFunction(start_time) {
            var request_time = new Date().getTime() - start_time;
            return request_time;
        }

        let response = await
            ajax(domain + '/api/api_new/bulkQuery/fetchMultiSelectOptions.php', {
                data: inputName
            }).then(response => {
                // Do something after response
                //// console.log('Request Time (ms)', getRquestSuccessFunction(start_time)); // DEBUG
                // console.log(response);

                // if (inputName[1] === 'city') {
                //     console.log(response.map(x => ({ label: toTitleCase(x), optionola: x })));
                //     return response.map(x => ({ label: toTitleCase(x), optionola: x }));
                // }

                return (response);

            }).catch(function (error) {
                if (error.response) {
                    // If there was an error
                }
            });
        return response
    }


    // State Tracker multiSelectOptions
    const [search_county, setSearch_county] = useState([]);
    const [search_townCity, setSearch_townCity] = useState([]);
    const [search_useCodes, setSearch_useCodes] = useState([]);

    // fetch_multiSelectOptions
    useEffect(() => {

        let mounted = true;

        const fetchData = async () => {

            // Do individually so that we dont have to wait for one to finish
            const countydata = await fetch_multiSelectOptions([1, 'county']);
            const townCitydata = await fetch_multiSelectOptions([0, 'city']);
            const useCodes = await fetch_multiSelectOptions([3, 'major_div, full_code, description']);

            // console.log(useCodes);

            // SET OPTIONS
            //
            // (1) Set countyMap options
            setSearch_county(countydata);
            ////console.log('countyMap:', countydata); // DEBUG

            // (2) Set countyMap options
            setSearch_townCity(townCitydata);
            ////console.log('cityTownMap:', townCitydata); // DEBUG

            // (3) Set useCode options
            setSearch_useCodes(useCodes);
            //// console.log('useCodes:', useCodes); // DEBUG
        }

        // Run it!
        fetchData();

    }, []); // Run once


    return (
        <div>
            <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                    <Typography>Search</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Search county={search_county} townCity={search_townCity} update_queryRequest={props.update_queryRequest} queryRequest={props.queryRequest} />
                </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
                    <Typography>Filter</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Filter update_queryRequest={props.update_queryRequest} queryRequest={props.queryRequest} useCodes={search_useCodes} />
                </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
                    <Typography>Quotes</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Quote update_queryRequest={props.update_queryRequest} queryRequest={props.queryRequest} />
                </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
                <AccordionSummary aria-controls="panel4d-content" id="panel4d-header">
                    <Typography>Mailing List</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <MailingList update_queryRequest={props.update_queryRequest} queryRequest={props.queryRequest} />
                </AccordionDetails>
            </Accordion>
            {/* <Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
                <AccordionSummary aria-controls="panel5d-content" id="panel5d-header">
                    <Typography>Export</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <h1>📤</h1>
                </AccordionDetails>
            </Accordion> */}
        </div>
    );
}