import React from 'react';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { currencyUSD } from '../../utilities/utilities'
import { Hidden } from '@mui/material';

export default function QuotesDataGrid(props) {


    // Build Rows
    function createRow(id, title, coverage) {

        let rowMatrix = { id: id, col1: title, style: 'coverage' };

        for (const [key, value] of Object.entries(props.quoteResult.quotes)) {
            if (coverage === 'premium') {
                Object.assign(rowMatrix, { [key]: currencyUSD(value.premium) });
            }
            // Wind Deductible Login (including percentages)
            else if (coverage === 'windDeductible') {
                // If there is no windDeductible or it matches the AOP - change value to "none"
                if (value.coverages['windDeductible'] === 0 || value.coverages['aopDeductible'] === value.coverages['windDeductible']) {
                    Object.assign(rowMatrix, { [key]: 'None' });
                }
                // If the deductible is a percent - reformat
                else if (coverage === 'windDeductible' && (value.coverages['windDeductible'] < 100)) {
                    Object.assign(rowMatrix, { [key]: `${value.coverages['windDeductible']}% (${currencyUSD(value.coverages['coverageA'] * value.coverages['windDeductible'] * 0.01)}) ` });
                } else {
                    Object.assign(rowMatrix, { [key]: currencyUSD(value.coverages[coverage]) });
                }
            }
            else {
                Object.assign(rowMatrix, { [key]: currencyUSD(value.coverages[coverage]) });
            }
        }
        // console.log(rowMatrix);
        return rowMatrix;
    }

    const row1_building = createRow(1, 'Premium', 'premium');
    const row3_building = createRow(3, 'Building', 'coverageA');
    const row4_building = createRow(4, 'Other Structures', 'coverageB');
    const row5_building = createRow(5, 'Personal Property', 'coverageC');
    const row6_building = createRow(6, 'Loss of Use', 'coverageD');
    const row7_building = createRow(7, 'Personal Liability', 'coverageE');
    const row8_building = createRow(8, 'Medical Payments', 'coverageF');
    const row10_building = createRow(10, 'Deductible', 'aopDeductible');
    const row11_building = createRow(11, 'Seperate Deductible', 'windDeductible');

    const rows = [
        row1_building,
        { id: 2, col1: 'Coverages', style: 'subHeading' },
        row3_building,
        row4_building,
        row5_building,
        row6_building,
        row7_building,
        row8_building,
        { id: 9, col1: 'Deductibles', style: 'subHeading' },
        row10_building,
        row11_building
    ];

    // Build Columns
    let columns = [{ field: 'col1', headerName: 'Company', width: 160, sortable: false }];
    for (const [key, value] of Object.entries(props.quoteResult.quotes)) {
        columns.push({ field: key, headerName: value.name, width: 100, sortable: false });
    }


    return (
        <DataGridPro
            columns={columns}
            rows={rows}
            autoHeight
            hideFooter
            rowHeight={26}
            initialState={{ pinnedColumns: { left: ['col1'] } }}
            disableColumnMenu={true}
            disableColumnReorder={true}
            disableColumnResize={true}
            sx={{
                // border: 'solid 2px',
                backgroundColor: 'grey: 500',
                borderRadius: '10px',
                overflow: 'hidden',
                paddingRight: '10px',
                // Header
                '& .MuiDataGrid-columnHeader': {
                    // marginRight: '10px',
                }, '& .MuiDataGrid-columnHeaders': {
                    borderBottom: 'none',
                    minHeight: '0px !important', // Yikes! Its important 😬
                    maxHeight: '40px !important', // Yikes! Its important 😬
                }, '& .MuiDataGrid-columnHeaderTitle': {
                    lineHeight: '40px',
                }, '& .MuiDataGrid-columnHeaderTitleContainer': {
                    padding: 0,
                },
                '& 	.MuiDataGrid-virtualScroller': {
                    marginTop: '35px !important', // Yikes! Its important 😬
                },
                // Pinned Header
                '& .MuiDataGrid-pinnedColumns': {
                    backgroundColor: 'white',
                    boxShadow: 'none',
                    paddingLeft: '10px',
                }, '& .MuiDataGrid-pinnedColumnHeaders': {
                    backgroundColor: 'white',
                    boxShadow: 'none',
                }, '& .MuiDataGrid-columnSeparator': {
                    display: 'none',
                }, '& .MuiDataGrid-cell--textLeft': {
                    paddingLeft: '10px',
                },
                // Rows
                '& .MuiDataGrid-row ': {
                    '&:hover': {
                        bgcolor: 'transparent'
                    }
                },
                '& .MuiDataGrid-row.Mui-hovered ': {
                    bgcolor: 'transparent'
                },
                // Cells
                '& .MuiDataGrid-cell ': {
                    borderBottom: 'none',
                    '&:hover': {
                        bgcolor: 'transparent'
                    }
                },
                //
                // Specific Selectors Below Here
                '& .super-app-theme--subHeading': {
                    // color: 'black',
                    fontWeight: 'bold',
                    marginTop: '5px',
                    padding: 0,
                    minHeight: '30px !important', // Yikes! Its important 😬
                    maxHeight: '30px !important', // Yikes! Its important 😬
                    boxShadow: '0px -3px 1px -2px rgba(0,0,0,0.2)',
                    '& .MuiDataGrid-cell': {
                        padding: 0,
                        minHeight: '30px !important', // Yikes! Its important 😬
                        maxHeight: '30px !important', // Yikes! Its important 😬
                    }
                },
                '& .super-app-theme--coverage': {
                    // paddingLeft: '10px',
                },


            }}
            getRowClassName={(params) => `super-app-theme--${params.row.style}`}
        />
    );
}