import * as React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete, { autocompleteClasses } from "@mui/material/Autocomplete";
import useMediaQuery from "@mui/material/useMediaQuery";
import ListSubheader from "@mui/material/ListSubheader";
import Popper from "@mui/material/Popper";
import { useTheme, styled } from "@mui/material/styles";
import { VariableSizeList } from "react-window";
import Typography from "@mui/material/Typography";

import { Checkbox } from '@mui/material';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;


const LISTBOX_PADDING = 8; // px


function renderRow(props) {

    const { data, index, style } = props;
    const dataSet = data[index];
    const inlineStyle = {
        ...style,
        top: style.top + LISTBOX_PADDING
    };

    if (dataSet.hasOwnProperty("group")) {
        return (
            <ListSubheader key={dataSet.key} component="div" style={inlineStyle}>
                {dataSet.group}
            </ListSubheader>
        );
    }

    //// console.log(dataSet, dataSet[2].selected); // DEBUG

    return (
        <Typography component="li" {...dataSet[0]} noWrap style={inlineStyle}>
            <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                style={{ marginRight: 8 }}
                checked={dataSet[2].selected}
            />
            {dataSet[1]}
        </Typography>
    );
}

const OuterElementContext = React.createContext({});

const OuterElementType = React.forwardRef((props, ref) => {
    const outerProps = React.useContext(OuterElementContext);
    return <div ref={ref} {...props} {...outerProps} />;
});

function useResetCache(data) {
    const ref = React.useRef(null);
    React.useEffect(() => {
        if (ref.current != null) {
            ref.current.resetAfterIndex(0, true);
        }
    }, [data]);
    return ref;
}

// Adapter for react-window
const ListboxComponent = React.forwardRef(function ListboxComponent(props, ref) {

    // console.log(props);

    const { children, ...other } = props;
    const itemData = [];
    children.forEach((item) => {
        itemData.push(item);
        itemData.push(...(item.children || []));
    });

    const theme = useTheme();
    const smUp = useMediaQuery(theme.breakpoints.up("sm"), {
        noSsr: true
    });

    const itemCount = itemData.length;
    const itemSize = smUp ? 36 : 48;

    const getChildSize = (child) => {
        if (child.hasOwnProperty("group")) {
            return 48;
        }
        return itemSize;
    };

    const getHeight = () => {
        if (itemCount > 8) {
            return 8 * itemSize;
        }
        return itemData.map(getChildSize).reduce((a, b) => a + b, 0);
    };

    const gridRef = useResetCache(itemCount);

    return (
        <div ref={ref}>
            <OuterElementContext.Provider value={other}>
                <VariableSizeList
                    itemData={itemData}
                    height={getHeight() + 2 * LISTBOX_PADDING}
                    width="100%"
                    ref={gridRef}
                    outerElementType={OuterElementType}
                    innerElementType="ul"
                    itemSize={(index) => getChildSize(itemData[index])}
                    overscanCount={5}
                    itemCount={itemCount}
                >
                    {renderRow}
                </VariableSizeList>
            </OuterElementContext.Provider>
        </div>
    );
});

// Prop Types Causes an error.. I beleive this is just to check the propType sooooo we'll jsut leave this commented out for now.
// ListboxComponent.propTypes = {
//     children: PropTypes.node
// }; 

// Remove horizontal scrolling in list, ect.
const StyledPopper = styled(Popper)({
    [`& .${autocompleteClasses.listbox}`]: {
        boxSizing: "border-box",
        "& ul": {
            padding: 0,
            margin: 0
        }
    }
});

export default function VirtualizedAutoComplete(props) {

    return (
        <Autocomplete
            multiple
            onChange={props.onChange(props.name)}
            disableCloseOnSelect
            loading={!props.name ? true : false}
            loadingText='...'
            sx={{ width: '100%' }}
            size='small'
            variant="filled"
            values={props.values}
            defaultValue={props.defaultValue}
            disableListWrap
            PopperComponent={StyledPopper}
            ListboxComponent={ListboxComponent}
            // optionRender
            // options={typeof props.options[0] === 'object' ? Object.entries(props.options.map(optioned => optioned).sort((a, b) => a[props.optionValueName].localeCompare(b[props.optionValueName]))) : props.options}
            options={typeof props.options[0] === 'object' ? props.options.map(optioned => optioned[props.optionValueName]).sort((a, b) => a.localeCompare(b)) : props.options}
            // getOptionLabel={typeof props.options[0] === 'object' ? props.options.map(optioned => optioned[props.optionRender]).sort((a, b) => a.localeCompare(b)) : props.options[0]}
            // groupBy={typeof props.options[0] === 'object' ? (option) => option[props.optionIndex] : (option) => option[0].toUpperCase()}
            // groupBy={(option) => option.optionIndex}
            
            renderInput={(params) => <TextField {...params} label={props.label} />}
            renderOption={(props, option, { selected }) => [props, option, { selected }]}


            renderGroup={(params) => params}
        />
    );
}
