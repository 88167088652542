import * as React from 'react';
import { useState, useEffect } from 'react';
import { toTitleCase } from '../../../utilities/utilities';

import Grid from '@mui/material/Grid';
import { Box } from '@mui/material';
import { Checkbox } from '@mui/material';
import { Autocomplete } from '@mui/material';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { TextField } from '@mui/material';
import { FilledInput } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';


const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export default function Quote(props) {

    // Options 
    const companies = [
        { id: 'mpiua', name: 'MPIUA', status: true },
        { id: 'norfolkAndDedham', name: 'Norfolk & Dedham', status: true },
        { id: 'dorchesterMutual', name: 'Dorchester Mutual', status: true },
        { id: 'commerce', name: 'Commerce', status: false }
    ];

    // Values from the form! 😛
    const [values, setValues] = useState({
        // companies: [{ id: 'mpiua', name: 'MPIUA', status: true }],
        companies: [],
        replacementCost: 250,
        forceRequote: true,
        deductible: 1000,
        returnDetail: false
    });

    // Put props function in new variable to avoid using props as a dependancy in useEffect
    const update_queryRequest_props = props.update_queryRequest;
    // Update the queryRequest
    useEffect(() => {
        update_queryRequest_props(values, 'quoteRequest');
    }, [values, update_queryRequest_props]);


    // CHECKBOX CHANGE
    const handleCheckboxChange = (prop) => (event) => {
        // Make a copy of the state
        let intermediate = values;
        // Update the value using the prop and index
        intermediate[prop] = event.target.checked;
        // Define the prop (with its index's)
        const udpateMe = intermediate[prop];
        // Reset the state
        setValues({ ...values, [prop]: udpateMe });
        // console.log('values: ', values);
    }

    // AUTO-COMPELTE CHANGE
    const handleAutoCompleteChange = (prop) => (event, value) => {
        console.log('CHANGING!'); // DEBUG

        // Make a copy of the state
        let intermediate = values;
        // Update the value using the prop and index
        intermediate[prop] = value;
        // Reset the state
        setValues({ ...values, [prop]: value });
        console.log('values: ', values); // DEBUG
    };

    // Update values onchange
    const handleChange = (prop, index, value = null) => (event) => {
        // Make a copy of the state
        let intermediate = values;

        intermediate[prop] = event.target.value;

        // Define the prop (with its index's)
        const udpateMe = intermediate[prop];
        // Reset the state
        setValues({ ...values, [prop]: udpateMe });
        // console.log('values: ', values);
    };


    // Companies To Quote
    // Recplacement Cost


    return (
        <Grid container spacing={2}>
            {/* Companies To Quote */}
            <Grid item sx={{ width: '100%' }}>
                <Box sx={{ display: 'flex', bgcolor: 'background.paper', borderRadius: 1 }} >
                    <Box sx={{ display: 'flex', minWidth: '175px', alignSelf: 'center' }}>Comanies to Quote</Box>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Autocomplete
                                multiple
                                id="checkboxes-tags-demo"
                                // onChange={handleAutoCompleteChange}
                                onChange={(event, newValue) => {
                                    // Make a copy of the state
                                    let intermediate = values;
                                    // Update the value using the prop and index
                                    intermediate['companies'] = newValue;
                                    // Reset the state
                                    setValues(intermediate);
                                    console.log('values: ', intermediate); // DEBUG
                                }}
                                options={companies}
                                value={values[0]}
                                isOptionEqualToValue={(option, value) => option.id === value.id}
                                disableCloseOnSelect
                                getOptionLabel={(option) => option.name}
                                getOptionDisabled={(option) =>
                                    option.status === false
                                }
                                renderOption={(props, option, { selected }) => (
                                    <li {...props}>
                                        <Checkbox
                                            icon={icon}
                                            checkedIcon={checkedIcon}
                                            style={{ marginRight: 8 }}
                                            checked={selected}
                                        />
                                        {option.name}
                                    </li>
                                )}
                                // style={{ width: 500 }}
                                renderInput={(params) => (
                                    <TextField {...params} label="Companies" placeholder="" />
                                )}
                            />
                        </Grid>
                    </Grid>
                </Box>
            </Grid>
            {/* Replacement Cost */}
            <Grid item sx={{ width: '100%' }}>
                <Box sx={{ display: 'flex', bgcolor: 'background.paper', borderRadius: 1 }} >
                    <Box sx={{ display: 'flex', minWidth: '175px', alignSelf: 'center' }}>Replacement Cost</Box>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <FilledInput
                                id="replacementCost_request"
                                error={values['replacementCost'] < 100}
                                value={values['replacementCost']}
                                onChange={handleChange('replacementCost')}
                                startAdornment={<InputAdornment position="start">$</InputAdornment>}
                                inputProps={{
                                    'aria-label': 'weight',
                                }}
                                sx={{ width: '100%' }}
                                size='small'
                            />
                        </Grid>
                    </Grid>
                </Box>
            </Grid>
            {/* Force Requote */}
            <Grid item sx={{ width: '100%' }}>
                <Box sx={{ display: 'flex', bgcolor: 'background.paper', borderRadius: 1 }} >
                    <Box sx={{ display: 'flex', minWidth: '175px', alignSelf: 'center' }}>Force Requote</Box>
                    <Grid container spacing={2}>
                        <Grid item xs={8}>
                            <Checkbox
                                checked={values['forceRequote']}
                                onChange={handleCheckboxChange('forceRequote')}
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                        </Grid>
                    </Grid>
                </Box>
            </Grid>
            {/* Return Detailed Data */}
            <Grid item sx={{ width: '100%' }}>
                <Box sx={{ display: 'flex', bgcolor: 'background.paper', borderRadius: 1 }} >
                    <Box sx={{ display: 'flex', minWidth: '175px', alignSelf: 'center' }}>Return Detailed Data</Box>
                    <Grid container spacing={2}>
                        <Grid item xs={8}>
                            <Checkbox
                                checked={values['returnDetail']}
                                onChange={handleCheckboxChange('returnDetail')}
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                        </Grid>
                    </Grid>
                </Box>
            </Grid>
            {/* Deductible */}
            <Grid item sx={{ width: '100%' }}>
                <Box sx={{ display: 'flex', bgcolor: 'background.paper', borderRadius: 1 }} >
                    <Box sx={{ display: 'flex', minWidth: '175px', alignSelf: 'center' }}>Deductible</Box>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Select
                                id="deductibleSelect"
                                value={values['deductible']}
                                onChange={handleChange('deductible')}
                            >
                                <MenuItem value={1000}>$1,000</MenuItem>
                                <MenuItem value={2500}>$2,500</MenuItem>
                                <MenuItem value={5000}>$5,000</MenuItem>
                            </Select>
                        </Grid>
                    </Grid>
                </Box>
            </Grid>
        </Grid >
    );
}
