import * as React from 'react';
import { useState, useEffect } from 'react';

import Grid from '@mui/material/Grid';
import { Box, FilledInput, InputAdornment } from '@mui/material';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export default function MailingList(props) {

    // Values from the form! 😛
    const [values, setValues] = useState({
        daysPriorToPurchase: '30'
    });

    // Update values onchange
    const handleChange = (prop, index, value = null) => (event) => {
        // Make a copy of the state
        let intermediate = values;
        if (!index) {
            // Update the value using the prop and index
            intermediate[prop] = event.target.value;
        } else {
            // Update the value using the prop and index
            intermediate[prop][index] = event.target.value;
        }
        // Define the prop (with its index's)
        const udpateMe = intermediate[prop];
        // Reset the state
        setValues({ ...values, [prop]: udpateMe });
        // console.log('values: ', values);
    };

    // Put props function in new variable to avoid using props as a dependancy in useEffect
    const update_queryRequest_props = props.update_queryRequest;
    // Update the queryRequest
    useEffect(() => {
        update_queryRequest_props(values, 'mailingList');
    }, [values, update_queryRequest_props]);

    return (
        <Grid container spacing={2}>
            <Grid item sx={{ width: '100%' }}>
                <Box sx={{ display: 'flex', bgcolor: 'background.paper', borderRadius: 1 }} >
                    <Box sx={{ display: 'flex', minWidth: '175px', alignSelf: 'center' }}>Days Prior to Purchase</Box>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <FilledInput
                                id="mailingList_daysPriorToPurchase"
                                error={values['daysPriorToPurchase'] > 365 ? true : false}
                                onChange={handleChange('daysPriorToPurchase')}
                                defaultValue={'30'}
                                endAdornment={<InputAdornment position="end">days</InputAdornment>}
                                inputProps={{
                                    'aria-label': 'weight',
                                }}
                                sx={{ width: '100%' }}
                                size='small'
                            />
                        </Grid>
                    </Grid>
                </Box>
            </Grid>
        </Grid>
    );
}
