import * as React from 'react';
import {
    DataGridPro,
    useGridApiContext,
    gridColumnVisibilityModelSelector,
    GridEvents,
    useGridApiRef,
} from "@mui/x-data-grid-pro";
import { Divider } from '@mui/material';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';


function DetailPanelContent({ row: rowProp }) {

    //// console.log(rowProp);

    const INITIAL_GROUPING_COLUMN_MODEL = ['company'];

    // This code removes duplicate grouping column (magic)
    const useKeepGroupingColumnsHidden = (apiRef, columns, initialModel, leafField) => {
        const prevModel = React.useRef(initialModel);

        React.useEffect(() => {
            apiRef.current.subscribeEvent(GridEvents.rowGroupingModelChange, (newModel) => {
                const columnVisibilityModel = {
                    ...gridColumnVisibilityModelSelector(apiRef),
                };

                newModel.forEach((field) => {
                    if (!prevModel.current.includes(field)) {
                        columnVisibilityModel[field] = false;
                    }
                });
                prevModel.current.forEach((field) => {
                    if (!newModel.includes(field)) {
                        columnVisibilityModel[field] = true;
                    }
                });
                apiRef.current.setColumnVisibilityModel(columnVisibilityModel);
                prevModel.current = newModel;
            });
        }, [apiRef]);
        return React.useMemo(
            () =>
                columns.map((colDef) =>
                    initialModel.includes(colDef.field) ||
                        (leafField && colDef.field === leafField)
                        ? { ...colDef, hide: true }
                        : colDef,
                ),
            [columns, initialModel, leafField],
        );
    };


    const columnsDefined = [
        {
            field: 'company',
            headerName: 'Company',
            flex: 0.33
        },
        {
            field: 'metric',
            headerName: 'Metric',
            flex: 0.33,
        },
        {
            field: 'value',
            headerName: 'Value',
            type: 'string',
            flex: 0.33
            // valueGetter: ({ row }) => row.quantity * row.unitPrice,
        },
    ];

    const apiRef = useGridApiRef();

    const columns = useKeepGroupingColumnsHidden(
        apiRef,
        columnsDefined,
        INITIAL_GROUPING_COLUMN_MODEL,
    );


    let rows = [];
    function createRows(rowProp) {
        let i = 0;
        for (const [key, value] of Object.entries(JSON.parse(rowProp.breakdowns))) {
            for (const [key2, value2] of Object.entries(value)) {
                for (const [key3, value3] of Object.entries(value2)) {
                    rows.push({ id: i, company: key, metric: value3[1], value: value3[0] });
                    i++;
                }
            }
        };
        //// console.log('rows: ', rows); // DEBUG
    }

    // RESEARCH
    const columnsDefined__research = [
        {
            field: 'metric',
            headerName: 'Metric',
            flex: 0.5,
        },
        {
            field: 'value',
            headerName: 'Value',
            type: 'string',
            flex: 0.5
        },
    ];


    let rows__research = [];
    function createRows__research(rowProp) {
        let i = 0;
        for (const [key, value] of Object.entries(JSON.parse(rowProp.research))) {
            for (const [key2, value2] of Object.entries(value)) {
                rows__research.push({ id: `${i}_r`, metric: key2, value: value2 });
                i++;
            }
        };
        //// console.log('rows__research: ', rows__research); // DEBUG
    }

    createRows(rowProp);
    createRows__research(rowProp);

    // Disable detail panel content scroll
    const apiRefWidth = useGridApiContext();
    const [width, setWidth] = React.useState(() => {
        const dimensions = apiRefWidth.current.getRootDimensions();
        return dimensions.viewportInnerSize.width;
    });

    const handleViewportInnerSizeChange = React.useCallback(() => {
        const dimensions = apiRefWidth.current.getRootDimensions();
        setWidth(dimensions.viewportInnerSize.width);
    }, [apiRefWidth]);


    return (
        <Stack sx={{
            py: 2, height: 1, boxSizing: 'border-box', position: 'sticky', left: 0, width
        }} direction="column">
            <Paper sx={{ flex: 1, mx: 'auto', width: '90%', p: 1 }}>
                <Stack direction="column" spacing={1} sx={{ height: 1 }}>
                    <Typography variant="h6"> {`${rowProp.SITE_ADDR} ${rowProp.CITY}`}</Typography>
                    <Grid container>
                        <Grid item md={8}>
                            <Typography variant="body2" color="textSecondary">{`Owner: ${rowProp.mailingAddressF}`}</Typography>
                            <Typography variant="body2" align="left" color="textSecondary">{`Last Purchased: ${rowProp.purchaseDateFull}`}</Typography>
                        </Grid>
                        <Grid item md={4}>
                            <Typography variant="body2" align="right" color="textSecondary">
                                Location ID
                            </Typography>
                            <Typography variant="body1" align="right">
                                {`${rowProp.LOC_ID}`}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Divider />
                    <Typography variant="body1">Breakdowns</Typography>
                    <div style={{ height: 300, width: '100%' }}>
                        <DataGridPro
                            density="compact"
                            apiRef={apiRef} // Needed to remove duplicat groupding column
                            columns={columns}
                            initialState={{
                                rowGrouping: {
                                    model: INITIAL_GROUPING_COLUMN_MODEL,
                                },
                            }}
                            experimentalFeatures={{
                                rowGrouping: true,
                            }}
                            rows={rows}
                            sx={{ flex: 1 }}
                            hideFooter
                        />
                    </div>
                    {/* <Divider /> */}
                    <Typography variant="body1">Research</Typography>
                    <div style={{ height: 300, width: '100%' }}>
                        <DataGridPro
                            density="compact"
                            columns={columnsDefined__research}
                            rows={rows__research}
                            sx={{ flex: 1 }}
                            hideFooter
                        />
                    </div>
                </Stack>
            </Paper>
        </Stack>
    );
}

export default DetailPanelContent;