import { Paper } from "@mui/material";
import Grid from '@mui/material/Grid';
import { Button } from "@mui/material";

import { ajax, domain } from "../../utilities/utilities";

function Controls(props) {

    // On Click Function for 'submitRequest' button
    const submitRequestClick = () => {
        submitRequest(props.queryRequest);
    }

    async function submitRequest(queryRequest) {

        console.log(queryRequest);
        // Timer Function
        var start_time = new Date().getTime();
        props.setQueryRequestLoading(true);
        function getRquestSuccessFunction(start_time) {
            var request_time = new Date().getTime() - start_time;
            return request_time;
        }


        // Make the call return "running" right away, then run another ajax call that uses the request count (number of items to process) and number not quoted - use time and calculate progress


        let response = await
            // ajax(domain + '/api/api_new/bulkQuery/processRequest.php', {
            ajax(domain + '/api/elliott_php/quoteMaster/query/processRequest.php', {
                data: queryRequest
            }).then(response => {
                // Do something after response
                console.log('Request Time (ms)', getRquestSuccessFunction(start_time));
                // console.log(response);
                props.setQueryRequestLoading(false);
                props.setQueryResponse(response)
                return (response);

            }).catch(function (error) {
                window.alert('There was an error');
                props.setQueryRequestLoading(false);
                // If there was an error
                if (error.response) {
                    //
                }
            });
        return response
    }



    return (
        <Grid item sx={{ width: '100%' }}>
            <Button variant="contained" onClick={submitRequestClick} disabled={!!props.queryRequestLoading ? true : false}>Request</Button>
            {/* <Button variant="contained" onClick={submitRequestClick} onClick={showNames}>Names</Button> */}
        </Grid>
    );
}

export default Controls;

