// Domain (API)
export const domain = 'https://www.testola.com';

// Camel Case Converter
export function camelize(str) {
    console.log(str);
    const strWoU = str.replaceAll('_', ' '); // str without underscore
    return strWoU.replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
        return index === 0 ? word.toLowerCase() : word.toUpperCase();
    }).replace(/\s+/g, '');
}

// Title Case Converter
export function toTitleCase(str) {
    return str.replace(
        /\w\S*/g,
        function (txt) {
            return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
        }
    );
}

// AJAX
export async function ajax(url = '', data = {}) {
    //// console.log("firing source AJAX function"); // DEBUG
    // Default options are marked with *
    const response = await fetch(url, {
        method: 'POST', // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, *cors, same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'same-origin', // include, *same-origin, omit
        headers: {
            'Content-Type': 'application/json'
            // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        redirect: 'follow', // manual, *follow, error
        referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        body: JSON.stringify(data) // body data type must match "Content-Type" header
    });
    return response.json(); // parses JSON response into native JavaScript objects
}

// Ajax example below
// ajax(props.domain + '/api/api__router.php', {
//     coordinates: props.coordinates,
//     opperation: 'singleQuote'
// }).then(response => {
//     // Do something after response
// }).catch(function (error) {
//     if (error.response) {
//         // if there was an error
//     }
// });

export function dateYYYYMMDD(dateString) {
    // var dateString = "20120515"; // DEBUG (ex:)
    var year = dateString.substring(0, 4);
    var month = dateString.substring(4, 6);
    var day = dateString.substring(6, 8);
    var date = new Date(year, month - 1, day);
    return date;
}

export function subtractDays(numOfDays, date = new Date()) {
    date.setDate(date.getDate() - numOfDays);
    return date;
}

export function dateMMDD(dateString) {
    var month = dateString.getUTCMonth() + 1; //months from 1-12
    // console.log(month);
    if (month < 10) {
        month = "0" + month;
    }
    var day = dateString.getUTCDate();
    if (day < 10) {
        day = "0" + day;
    }
    var date = `${month}/${day}`;
    return date;
}

export function dateMMDDYYYY(dateString) {
    var month = dateString.getUTCMonth() + 1; //months from 1-12
    // console.log(month);
    var year = dateString.getUTCFullYear();
    if (month < 10) {
        month = "0" + month;
    }
    var day = dateString.getUTCDate();
    if (day < 10) {
        day = "0" + day;
    }
    var date = `${month}/${day}/${year}`;
    return date;
}

export function currencyUSD(value) {

    // Create our number formatter.
    var formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        // These options are needed to round to whole numbers if that's what you want.
        //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
        maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
    });

    return formatter.format(value); /* $2,500.00 */
}

export function nameDescrambler(value) {
    // Unscramble a name from the assessors database

    let fullName;
    let firstName;
    let lastName;

    // Bypass Name and create new first/last name
    // Options to bypass
    const optionsToBypass = ['llc', 'corp', 'estate'];
    if (optionsToBypass.some(el => value.toLowerCase().includes(el))) {
        fullName = value;
        firstName = value;
        lastName = value;

    }
    // No Bypass
    else {

        // If middle initial is first (or a one letter name is first, remove it)
        if (value.split(' ')[0].length === 1) {
            value = value.substr(value.indexOf(" ") + 1);
        }

        // Remove all multiple spaces
        value = value.replace(/ +(?= )/g, '');

        // Switch for removing Trusts and stuff
        const optionsToRemove = ['trustee', 'trustees', 'trust'];

        // If value includes any item from the optionsToRemove array (made non-case sensitive by making everything lowercase)
        if (optionsToRemove.some(el => value.toLowerCase().includes(el))) {
            ////console.log(value); // DEBUG
            // Remove Last word from value
            const lastWord = value.lastIndexOf(' ');
            const withoutLastWord = value.substring(0, lastWord);
            // Update Value
            value = withoutLastWord.trim();
            // console.log(value);
        }

        // Get first word in string
        const firstWord = value.split(' ')[0];

        // Create new string without that first word
        const minusFirstWord = value.substr(value.indexOf(" ") + 1);

        // Append that word to the end
        fullName = minusFirstWord.concat(' ', firstWord);

        // Get first name
        const assumbedFirstName = fullName.split(' ')[0];

        // If the new first name has a length greater than 1, we can assume the name was entered incorrectyly into the database (last, middle, first)
        if (assumbedFirstName.length > 1) {
            // Re-Define
            firstName = assumbedFirstName.replace(/[^A-Za-z0-9]/g, "");
            lastName = firstWord.replace(/[^A-Za-z0-9]/g, "");
        }
        // Else it was entered correctly (first, middle, last)
        else {
            fullName = value.split(' ')[0];
            firstName = firstWord.replace(/[^A-Za-z0-9]/g, "");
            lastName = value.split(' ')[value.split(' ').length - 1].replace(/[^A-Za-z0-9]/g, "");

            // Remove trailing text
            const optionsToRemoveTrailing = ['trustee', 'trustees', 'trust', 'jr'];
            if (optionsToRemoveTrailing.some(el => lastName.toLowerCase().includes(el))) {
                lastName = value.split(' ')[value.split(' ').length - 2].replace(/[^A-Za-z0-9]/g, "");
            }
        }

    }

    return [fullName, firstName, lastName];
}

// Residency Presumption
export function residencyPresumtion(siteCity, mailingCity) {
    if (siteCity.toLowerCase() === mailingCity.toLowerCase()) {
        return 'primary';
    } else {
        return 'nonPrimary';
    }
}