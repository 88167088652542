import { Container, Paper, Grid, Button } from "@mui/material";
import { Box } from "@mui/system";

import { ajax, domain } from "../utilities/utilities";

function Settings() {

  async function manualInsert() {
    console.log(domain + '/api/elliott_php/utilities/manualInsert.php');
    // Timer Function
    var start_time = new Date().getTime();
    function getRquestSuccessFunction(start_time) {
      var request_time = new Date().getTime() - start_time;
      return request_time;
    }

    let response = await
      ajax(domain + '/api/elliott_php/utilities/manualInsert.php', {
        data: ["requiredData"]
      }).then(response => {
        // Do something after response
        console.log('Request Time (ms)', getRquestSuccessFunction(start_time));
        console.log(response);
        return (response);

      }).catch(function (error) {
        if (error.response) {
          // If there was an error
        }
      });
    return response
  }

  return (
    <Box>
      <Container>
        <Paper elevation={2}>
          {/* <Accordion>
  
          </Accordion> */}
          <Grid>
            <h1>⚙️</h1>
            You're in settings!
            <h6>The below "hidden" button is used for manual inserts</h6>
            <Button variant="outlined">Unhide Below Button</Button>
            {/* <Button variant="outlined" onClick={manualInsert}>Manual Insert</Button> */}
          </Grid>
        </Paper>
      </Container>
    </Box>
  );
}

export default Settings;
