import React, { useRef, useEffect, useState } from "react";
import Basemap from "@arcgis/core/Basemap";
import MapView from '@arcgis/core/views/MapView';
import Map from '@arcgis/core/Map';

import Query from "@arcgis/core/rest/support/Query";
import * as watchUtils from "@arcgis/core/core/watchUtils";

import FeatureLayer from "@arcgis/core/layers/FeatureLayer";

import MapDrawer from "../MapDrawer";

// React - index.js
import '@arcgis/core/assets/esri/themes/dark/main.css';

import classes from "./mapApp.module.css";

import { ajax, domain } from "../../utilities/utilities";

async function queryQuote(LOC_ID, returnData = false, force = true) {

  // Timer Function
  var start_time = new Date().getTime();
  function getRquestSuccessFunction(start_time) {
    var request_time = new Date().getTime() - start_time;
    return request_time;
  }

  let response = await
    ajax(domain + '/api/elliott_php/quoteMaster/query/query.php', {
      data: {
        loc_id: [LOC_ID],
        returnData: returnData,
        quoteRequest: {
          forceRequote: force,
          companies: 'all',
        },
        forceRequote: force,
      }
    }).then(response => {
      // Do something after response
      console.log('Request Time (ms)', getRquestSuccessFunction(start_time));
      console.log(response[0]);
      return (response[0]);

    }).catch(function (error) {
      if (error.response) {
        // If there was an error
      }
    });
  return response;
}




function MapApp(props) {

  // Handle Drawer State
  const [drawerOpen, setDrawerOpen] = useState(false);
  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };

  // Map Request Result Drawer State
  const [mapResult, setMapResult] = useState('');

  // Quote Request Result
  const [quoteResult, setQuoteResult] = useState(null);

  const mapDiv = useRef(null);

  // or create the basemap from a well known ID
  Basemap.fromId("topo-vector");

  useEffect(() => {

    if (mapDiv.current) {

      // Create FEATURELAYER from feature service - STATE
      const layer = new FeatureLayer({
        // URL to the service
        url: "https://services1.arcgis.com/hGdibHYSPO59RG1h/arcgis/rest/services/L3_TAXPAR_POLY_ASSESS_gdb/FeatureServer/0",
        minScale: 10000,
        popupEnabled: true,
        labelsVisible: false
      });

      // mpiuaLayer LABELING_INFO
      const MPIUAlabelClass = {
        // autocasts as new LabelClass()
        symbol: {
          type: "text",  // autocasts as new TextSymbol()
          color: "black",
          // haloColor: "blue",
          // haloSize: "1px",
          backgroundColor: "white",
          borderLineColor: "white",
          font: {  // autocast as new Font()
            family: "Arial",
            size: 12,
            weight: "normal"
          }
        },
        labelPlacement: "center-center",
        labelExpressionInfo: {
          expression: "$feature.RES_AREA"
        }
      };
      // Create FEATURELAYER from feature service - MY MPIUA FILE
      const mpiuaLayer = new FeatureLayer({
        url: "https://services8.arcgis.com/3mhzge0ELHDui1bu/arcgis/rest/services/mpiua_rate_test/FeatureServer/0",
        minScale: 10000,
        popupEnabled: true,
        labelsVisible: true,
        labelingInfo: [MPIUAlabelClass]
        // defaultPopupTemplateEnabled: true,
      });



      // Create the MAP (defines layers)
      const map = new Map({
        basemap: 'gray-vector',
        layers: [layer, mpiuaLayer],
      })

      // Create the VIEW (defines HTML element and map)
      const view = new MapView({
        container: mapDiv.current,
        map,
        center: [-70.83, 41.69],
        zoom: 16, // Zoom level,
      });

      layer.renderer = {
        type: "simple",  // autocasts as new SimpleRenderer()
        symbol: {
          type: "simple-fill",  // autocasts as new SimpleMarkerSymbol()
          size: 6,
          color: [0, 0, 0, 0],
          outline: {  // autocasts as new SimpleLineSymbol()
            width: 0.5,
            // color: "black"
            color: '#007ac2',
          }
        }
      };

      mpiuaLayer.renderer = {
        type: "simple",  // autocasts as new SimpleRenderer()
        symbol: {
          type: "simple-marker",  // autocasts as new SimpleMarkerSymbol()
          size: 0,
          color: [0, 0, 0, 0],
        }
      };

      // view.when(async () => {
      //   await layer.when();
      //   view.ui.add("location", "bottom-left");
      //   // console.log("View Ready");
      //   // console.log(layer.fieldsIndex);
      //   // console.log(layer.geometryType);
      //   // console.log(layer.labelsVisible);
      //   // console.log(layer.loadStatus);
      // });


      view.when(function () {
        view.on("click", function (event) {
          console.log(drawerOpen);
          if (drawerOpen !== false) {
            // close drawer
            setQuoteResult(null);
          } else {
            // Toggle side drawer
            toggleDrawer();

            view.hitTest(event).then(function (response) {

              // Remvoe old result and show the loading skeleton
              setQuoteResult(null);

              const feature = response.results[0].graphic;
              //// console.log(feature.attributes); // DEBUG

              // Using the queried feature (generalized), requery the featureLayer using the OBJECTID
              // Define query details
              const query = new Query();
              query.where = "OBJECTID = " + feature.attributes['OBJECTID'];
              query.outSpatialReference = { wkid: 4326 };
              query.returnGeometry = false;
              query.outFields = ["*"];

              // Requery layer
              layer.queryFeatures(query).then(function (results) {
                ////console.log(results.features[0].attributes);  // prints the array of features to the console
                setMapResult(results.features[0].attributes);

                // Query the database
                // setQuoteResult(queryQuote(results.features[0].attributes['LOC_ID'], true));
                queryQuote(results.features[0].attributes['LOC_ID'], true).then((result) => {
                  setQuoteResult(result);
                });

              });
            });
          }
        });
      });


      // The Map Center box (yellow border)
      // watchUtils.whenTrue(view, "stationary", function () {
      //   let lon = parseInt(view.center.x); // with parseInt values are rounded to the full meter (coordinates in Web Mercator - EPSG:3857)
      //   let lat = parseInt(view.center.y);
      //   let zoomLevel = view.zoom;
      //   document.getElementById("location").innerHTML = "Map Center - Longitude: " + lon + " / Latitude: " + lat + " / Zoom level: " + zoomLevel;
      // });

    }
  }, []);

  // return cannot be in <Fragment/> because of useEffect() with mapDiv - more info at: https://www.debuggr.io/react-update-unmounted-component/

  return <div className={classes.mapDiv} ref={mapDiv}>
    {/* <div id="location" className={classes.location}></div> */}
    <div id="mapDrawer-container" ></div>
    <MapDrawer state={drawerOpen} toggleDrawer={toggleDrawer} mapResult={mapResult} quoteResult={quoteResult} />
  </div>
}

export default MapApp;
