import * as React from 'react';
import { DataGridPro, GridRowsProp, GridColDef, GridToolbar, GridOverlay, GridColumnsPanel } from "@mui/x-data-grid-pro";
import { useState, useEffect, Fragment } from "react";
import { dateYYYYMMDD, dateMMDD, dateMMDDYYYY, subtractDays, toTitleCase, nameDescrambler } from '../../../utilities/utilities';
import { Button, Box } from "@mui/material";
import LinearProgress from '@mui/material/LinearProgress';
import DetailPanelContent from "../bulkQueryDataGrid/detailPanelContent"
import { styled } from '@mui/material/styles';

import { currencyUSD } from '../../../utilities/utilities'

function BulkQueryDataGrid(props) {

    // Define the queryResponse (from props)
    const queryResponse = props.queryResponse;

    function CustomLoadingOverlay() {
        return (
            <GridOverlay>
                <div style={{ position: 'absolute', top: 0, width: '100%' }}>
                    <LinearProgress />
                </div>
            </GridOverlay>
        );
    }

    // BUILD COLUMNS
    function columnsConstructor(queryResponse) {
        // If no queryResponse, terminate
        if (!queryResponse) {
            return;
        }
        // Get first row
        const firstRow = queryResponse[0];

        // Declare Columns Variable
        let columnsConstruction = [];

        // VALUE GETTER
        const currencyFormatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
        });

        const usdPrice = {
            type: 'number',
            width: 130,
            valueFormatter: ({ value }) => currencyFormatter.format(Number(value)),
            cellClassName: 'font-tabular-nums',
        };

        const minWidth = 150;

        // Get Every Column Name
        for (const key of Object.keys(firstRow)) {
            //// console.log(`key: ${key}`); // DEBUG

            let prettyKey;
            let type;
            let description;
            let valueGetter;
            // Prettify Header Names
            switch (key) {
                case 'LOC_ID':
                    prettyKey = 'LOC_ID';
                    type = 'string';
                    break;
                case 'LAST_EDIT':
                    prettyKey = 'Last Edit';
                    type = 'date';
                    break;
                case 'BLDG_VAL':
                    prettyKey = 'Building Value';
                    // type = 'number';
                    break;
                case 'LAND_VAL':
                    prettyKey = 'Land Value';
                    break;
                case 'FY':
                    prettyKey = 'Fiscal Year Assessed';
                    break;
                case 'LOT_SIZE':
                    prettyKey = 'Lot Size';
                    type = 'number';
                    break;
                case 'LS_DATE':
                    prettyKey = 'Last Sale Date';
                    type = 'date';
                    break;
                case 'LS_PRICE':
                    prettyKey = 'Last Sale Price';
                    break;
                case 'FULL_STR':
                    prettyKey = 'Full Street Name';
                    type = 'string';
                    break;
                case 'LOCATION':
                    prettyKey = 'Secondary Location Info';
                    type = 'string';
                    break;
                case 'CITY':
                    prettyKey = 'City';
                    type = 'string';
                    break;
                case 'ZIP':
                    prettyKey = 'Zip';
                    type = 'string';
                    break;
                case 'OWNER1':
                    prettyKey = 'Owner';
                    type = 'string';
                    break;
                case 'OWN_ADDR':
                    prettyKey = 'Owner Address';
                    type = 'string';
                    break;
                case 'OWN_CITY':
                    prettyKey = 'Owner City';
                    type = 'string';
                    break;
                case 'OWN_STATE':
                    prettyKey = 'Owner State';
                    type = 'string';
                    break;
                case 'OWN_ZIP':
                    prettyKey = 'Owner Zip';
                    type = 'string';
                    break;
                case 'OWN_CO':
                    prettyKey = 'Owner Country';
                    type = 'string';
                    break;
                case 'YEAR_BUILT':
                    prettyKey = 'Year Built';
                    type = 'number';
                    break;
                case 'BLD_AREA':
                    prettyKey = 'Building Area';
                    break;
                case 'UNITS':
                    prettyKey = 'Units';
                    break;
                case 'RES_AREA':
                    prettyKey = 'Residential Area';
                    type = 'number';
                    break;
                case 'STYLE':
                    prettyKey = 'Style';
                    type = 'string';
                    break;
                case 'NUM_ROOMS':
                    prettyKey = 'Number of Rooms';
                    type = 'number';
                    break;
                case 'STORIES':
                    prettyKey = 'Stories';
                    type = 'number';
                    break;
                case 'CAMA_ID':
                    prettyKey = 'CAMA_ID';
                    type = 'string';
                    break;
                case 'SITE_ADDR':
                    prettyKey = 'Property Address';
                    type = 'string';
                    break;
                // COUNTY
                case 'county':
                    prettyKey = 'County';
                    type = 'string';
                    break;
                // COASTAL DISTANCE
                case 'coastDistance':
                    prettyKey = 'Coastal Distance (mi)';
                    type = 'number';
                    break;
                // COORDINATES
                case 'xcoord':
                    prettyKey = 'Longitude';
                    type = 'number';
                    break;
                case 'ycoord':
                    prettyKey = 'Latitude';
                    type = 'number';
                    break;
                // FLOOD ZONE
                case 'floodZones':
                    prettyKey = 'Flood Zones';
                    type = 'string';
                    break;
                // USE CODE
                case 'useCode_CAMA':
                    prettyKey = 'Use Code';
                    type = 'string';
                    break;
                // COASTAL DISTANCE
                case 'coastalDistance':
                    prettyKey = 'Coastal Distance';
                    type = 'string';
                    break;
                // DATES
                case 'dayOfYear':
                    prettyKey = 'Day Of Year';
                    type = 'string';
                    break;
                // POST CODE
                case 'POSTCODE':
                    prettyKey = 'Zip Code';
                    type = 'string';
                    break;
                default:
                    prettyKey = key;
                    type = 'string';
                    description = null;
            }

            columnsConstruction.push({ field: key, headerName: prettyKey, type: type, description: description, minWidth: minWidth });
        }

        // TODO Not all of these need to be pushed all the time.. Need to buidl a function that defines a template column list and sister function that toggles columns like "autoFloodQuoteExportFormat()"
        columnsConstruction.push({ field: 'mailingAddressF', headerName: 'Mailing Address', type: 'string', description: null, minWidth: minWidth });
        columnsConstruction.push({ field: 'purchaseDate', headerName: 'Purchase Date (mm/dd)', type: 'string', description: null, minWidth: minWidth });
        columnsConstruction.push({ field: 'mailDate', headerName: 'Mail Date', type: 'string', description: null, minWidth: minWidth });
        columnsConstruction.push({ field: 'nameDescrambled', headerName: 'Owner Name Proper', type: 'string', description: null, minWidth: minWidth });
        columnsConstruction.push({ field: 'nameDescrambled_First', headerName: 'Owner Name (First)', type: 'string', description: null, minWidth: minWidth });
        columnsConstruction.push({ field: 'nameDescrambled_Last', headerName: 'Owner Name (Last)', type: 'string', description: null, minWidth: minWidth });
        columnsConstruction.push({ field: 'replacementCost', headerName: 'Replacement Cost', type: 'number', description: null, minWidth: minWidth });
        columnsConstruction.push({ field: 'residency', headerName: 'Residency', type: 'string', description: null, minWidth: minWidth });

        columnsConstruction.push({ field: 'phone', headerName: 'Phone', type: 'number', description: null, minWidth: minWidth });
        columnsConstruction.push({ field: 'email', headerName: 'Email', type: 'number', description: null, minWidth: minWidth });
        columnsConstruction.push({ field: 'foundationType', headerName: 'Foundation Type', type: 'number', description: null, minWidth: minWidth });
        columnsConstruction.push({ field: 'premiumBuilding', headerName: 'Premium Building', type: 'number', description: null, minWidth: minWidth });
        columnsConstruction.push({ field: 'premiumBuildingContents', headerName: 'Premium BuildingContents', type: 'number', description: null, minWidth: minWidth });
        columnsConstruction.push({ field: 'yearBuilt_mail', headerName: 'Year Built', type: 'number', description: null, minWidth: minWidth });

        columnsConstruction.push({ field: 'mostCompetitive', headerName: 'Quote', type: 'number', description: null, minWidth: minWidth });
        columnsConstruction.push({ field: 'quoteCompany', headerName: 'Quote Company', type: 'string', description: null, minWidth: minWidth });

        columnsConstruction.push({ field: 'coverageA', headerName: 'Coverage A', type: 'number', description: null, minWidth: minWidth });

        // Update Columns State
        //// console.log('columnsConstruction: ', columnsConstruction); // DEBUG
        setColumns(columnsConstruction);

        // Set the column visibility
        control__ColumnVisibility(columnsConstruction, true);
    }

    // BUILD ROWS
    function rowsConstructor(queryResponse) {
        // If no queryResponse, terminate
        if (!queryResponse) {
            return;
        }

        // Declare ROWS Variable
        let rowsConstruction = [];

        // ? MEMO: LOOP INSIDE A LOOP - Each row has multiple columns..
        // For every entry (row)
        for (const [rowKey, rowValue] of queryResponse.entries()) {
            //// console.log(`rowKey: ${rowKey}`);

            // ID Array
            const rowsConstruction_id = [{ 'id': rowKey }];

            // Declare ROW Variable SINGLE
            let rowConstruction;

            // Loop through all the columns
            let rowDetails = {};
            rowDetails['id'] = rowKey;
            for (const [key, value] of Object.entries(rowValue)) {
                //// console.log(`key: ${key}`); // DEBUG
                //// console.log(`value: ${value}`); // DEBUG

                // Edit the value inline
                let newValue;
                switch (key) {
                    case 'LAST_EDIT':
                    case 'LS_DATE':
                        newValue = dateYYYYMMDD(value); // Convert to js Date
                        break;
                    case 'CITY':
                    case 'FULL_STR':
                    case 'SITE_ADDR':
                    case 'OWNER1':
                    case 'OWN_ADDR':
                    case 'OWN_CITY':
                    case 'STYLE':
                    // COUNTY
                    case 'county':
                        newValue = toTitleCase(value);
                        break;
                    // FLOOD ZONE
                    case 'floodZones':
                        // Declare flood zones text (to show in table)
                        let floodZones = '';

                        // Commas and parenthisis need to be encoded because of the way the values communicated between AJAX take place
                        // In this case we are replaceing comma with a '-' and parentisis with '2'
                        // Reformat response from server
                        const reformatted = JSON.parse(value.replaceAll('-', ',').replaceAll('2', '"')); // to JSON

                        // For each flood zone, check if the property is in that zone (boolean)
                        for (const zone in reformatted) {
                            // If so, is this the first zone
                            if (reformatted[zone] === 1) {
                                if (floodZones === '') {
                                    floodZones += zone;
                                }
                                // If not, add a comma (display text)
                                else {
                                    floodZones += ', ' + zone;
                                }
                            }
                        }
                        // If no zone exists (per FEMA)
                        if (floodZones === '') {
                            floodZones = 'n/a'
                        }
                        //// console.log(floodZones); // DEBUG
                        newValue = floodZones;
                        break;
                }

                // Insert Value
                const inserValue = !newValue ? value : newValue;

                rowDetails[key] = inserValue;
            }
            // Custuom Columns
            // Mailing List
            rowDetails['mailingAddressF'] = `${rowDetails['OWNER1']}\n${rowDetails['OWN_ADDR']}\n${rowDetails['OWN_CITY']}, ${rowDetails['OWN_STATE']}, ${rowDetails['OWN_ZIP']}`;
            // Purchase Date MM/DD
            rowDetails['purchaseDate'] = `${dateMMDD(rowDetails['LS_DATE'])}`;
            // Purchase Date MM/DD/YYY
            rowDetails['purchaseDateFull'] = `${dateMMDDYYYY(rowDetails['LS_DATE'])}`;
            // Mail Date
            //// console.log(dateMMDD(subtractDays(props.queryRequest.mailingList.daysPriorToPurchase, rowDetails['LS_DATE']))); // DEBUG
            rowDetails['mailDate'] = dateMMDD(subtractDays(props.queryRequest.mailingList.daysPriorToPurchase, rowDetails['LS_DATE']));
            // Name Descrambled (Owner Name Proper)
            rowDetails['nameDescrambled'] = `${nameDescrambler(rowDetails['OWNER1'])[0]}`;
            rowDetails['nameDescrambled_First'] = `${nameDescrambler(rowDetails['OWNER1'])[1]}`;
            rowDetails['nameDescrambled_Last'] = `${nameDescrambler(rowDetails['OWNER1'])[2]}`;
            // Replacement Cost
            rowDetails['replacementCost'] = `${rowDetails['RES_AREA'] * 250}`;

            // ZIP Code
            rowDetails['POSTCODE'] = `0${rowDetails['POSTCODE']}`;

            // Auto Flood Quote Fields
            rowDetails['yearBuilt_mail'] = `0101${rowDetails['YEAR_BUILT']}`;
            rowDetails['phone'] = `5555555555`;
            rowDetails['email'] = `email@email.com`;
            rowDetails['foundationType'] = `Basement`;

            // Coverage A
            if (!!rowDetails['research']) {
                rowDetails['coverageA'] = JSON.parse(rowDetails['research'])['coverages']['coverageA'];
            }

            if (!rowValue['mostCompetitive']) {
                rowDetails['mostCompetitive'] = 'Not Quoted';
                rowDetails['quoteCompany'] = 'Not Quoted';
            } else {
                // If there is no option (no quotes)
                if (rowValue['mostCompetitive'] === 'null') {
                    rowDetails['mostCompetitive'] = 'No Option';
                    rowDetails['quoteCompany'] = 'No Option';
                } else {
                    // Quote (most competitie)
                    rowDetails['mostCompetitive'] = currencyUSD(JSON.parse(rowValue['mostCompetitive'])['premium']);
                    // Quote (company)
                    rowDetails['quoteCompany'] = JSON.parse(rowValue['mostCompetitive'])['company'];
                }
            }

            // Combine the Row ID and the (multiple) columns
            // rowConstruction = rowsConstruction_id.concat(rowDetails);
            rowConstruction = rowDetails;
            rowsConstruction.push(rowConstruction);
            //// console.log('rowConstruction: ', rowConstruction);
        }

        // Update Columns State
        //// console.log('rowsConstruction: ', rowsConstruction); // DEBUG
        setRows(rowsConstruction);
    }


    // Reconstruct Columns on queryResponse udpate
    useEffect(() => {
        columnsConstructor(queryResponse);
        rowsConstructor(queryResponse);
    }, [queryResponse]);


    // INITIAL ROW & COLUMN VALUES
    const initialRows = [
        { id: 1, col1: '' }
    ];
    const initialColumns = [
        { field: 'col1', headerName: 'No Data Loaded', width: 150 }
    ];



    // ROW & COLUMN STATE
    const [columns, setColumns] = useState(initialColumns);
    // const [rows, setRows] = useState(initialRows);
    const [rows, setRows] = useState([]);



    // DATAGRID CONSTRUCTORS
    const rowsConstructed: GridRowsProp = rows;
    const columnsConstructed: GridColDef[] = columns;


    // VISIBLE COLUMN STATE (true/false)
    const [columnVisibilityModel, setColumnVisibilityModel] = useState({});



    // Visible Columns (only true) - DEFAULT
    const [visibleColumns, setVisibleColumns] = useState([
        'LOC_ID',
        'SITE_ADDR'
    ]);


    // Update visibleColumns array when columnVisibilityModel is changed
    useEffect(() => {
        //// console.log(columnVisibilityModel); // DEBUG
        if (Object.keys(columnVisibilityModel).length !== 0) {
            let visibleColumns__intermediate = [];

            for (const [key, value] of Object.entries(columnVisibilityModel)) {
                if (value === true) {
                    visibleColumns__intermediate.push(key);
                }
            }
            //// console.log(visibleColumns__intermediate); // DEBUG
            setVisibleColumns(visibleColumns__intermediate);
        }
    }, [columnVisibilityModel]);


    // Control the row visibility
    function control__ColumnVisibility(columns) {

        // Create temporary value to hold the current state
        let intermediate = {};

        // If called inside columnsConstructor() function
        if (columns[0].field) {
            // For every column, set its value to false
            for (const column of columns) {
                // If visbileColumns state includes the field, set the control__ColumnVisibility to true
                if (visibleColumns.includes(column.field)) {
                    intermediate[column.field] = true;
                } else {
                    intermediate[column.field] = false;
                }
            }
        }
        // Else being called by a column template selector funciton
        else {
            for (const column of columnsConstructed) {
                if (columns.includes(column.field)) {
                    intermediate[column.field] = true;
                } else {
                    intermediate[column.field] = false;
                }
            }
        }
        setColumnVisibilityModel(intermediate);
    }



    function autoFloodQuoteExportFormat() {
        console.log('autoFloodQuoteExportFormat');
        const updateTo = [
            'nameDescrambled_First',
            'nameDescrambled_Last',
            'phone',
            'email',
            'SITE_ADDR',
            'CITY',
            'STATE',
            'POSTCODE',
            'replacementCost',
            'foundationType',
            'yearBuilt_mail',
            'mailingAddress',
            'mailDate',
            'premiumBuilding',
            'premiumBuildingContents',
            'mailingAddressF',
            'residency'
        ];
        setVisibleColumns(updateTo);
        control__ColumnVisibility(updateTo);
    }
    function autoHomeQuoteExportFormat() {
        console.log('autoFloodQuoteExportFormat');
        const updateTo = [
            'nameDescrambled_First',
            'SITE_ADDR',
            'CITY',
            'STATE',
            'POSTCODE',
            'replacementCost',
            'mailingAddressF',
            'mostCompetitive',
            'quoteCompany',
        ];
        setVisibleColumns(updateTo);
        control__ColumnVisibility(updateTo);
    }

    // Map each row to the DetailPanelContent
    let getDetailPanelContent;
    getDetailPanelContent = React.useCallback(
        ({ row }) => <DetailPanelContent row={row} />,
        [],
    );
    // If there is no breakdown, dont show the option for expanding the row
    if (!!queryResponse) {
        if (!queryResponse[0].breakdowns) {
            getDetailPanelContent = null;
        }
    }


    // NO ROWS
    const StyledGridOverlay = styled('div')(({ theme }) => ({
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
        '& .ant-empty-img-1': {
            fill: theme.palette.mode === 'light' ? '#aeb8c2' : '#262626',
        },
        '& .ant-empty-img-2': {
            fill: theme.palette.mode === 'light' ? '#f5f5f7' : '#595959',
        },
        '& .ant-empty-img-3': {
            fill: theme.palette.mode === 'light' ? '#dce0e6' : '#434343',
        },
        '& .ant-empty-img-4': {
            fill: theme.palette.mode === 'light' ? '#fff' : '#1c1c1c',
        },
        '& .ant-empty-img-5': {
            fillOpacity: theme.palette.mode === 'light' ? '0.8' : '0.08',
            fill: theme.palette.mode === 'light' ? '#f5f5f5' : '#fff',
        },
    }));

    function CustomNoRowsOverlay() {
        return (
            <StyledGridOverlay>
                <svg
                    width="120"
                    height="100"
                    viewBox="0 0 184 152"
                    aria-hidden
                    focusable="false"
                >
                    <g fill="none" fillRule="evenodd">
                        <g transform="translate(24 31.67)">
                            <ellipse
                                className="ant-empty-img-5"
                                cx="67.797"
                                cy="106.89"
                                rx="67.797"
                                ry="12.668"
                            />
                            <path
                                className="ant-empty-img-1"
                                d="M122.034 69.674L98.109 40.229c-1.148-1.386-2.826-2.225-4.593-2.225h-51.44c-1.766 0-3.444.839-4.592 2.225L13.56 69.674v15.383h108.475V69.674z"
                            />
                            <path
                                className="ant-empty-img-2"
                                d="M33.83 0h67.933a4 4 0 0 1 4 4v93.344a4 4 0 0 1-4 4H33.83a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z"
                            />
                            <path
                                className="ant-empty-img-3"
                                d="M42.678 9.953h50.237a2 2 0 0 1 2 2V36.91a2 2 0 0 1-2 2H42.678a2 2 0 0 1-2-2V11.953a2 2 0 0 1 2-2zM42.94 49.767h49.713a2.262 2.262 0 1 1 0 4.524H42.94a2.262 2.262 0 0 1 0-4.524zM42.94 61.53h49.713a2.262 2.262 0 1 1 0 4.525H42.94a2.262 2.262 0 0 1 0-4.525zM121.813 105.032c-.775 3.071-3.497 5.36-6.735 5.36H20.515c-3.238 0-5.96-2.29-6.734-5.36a7.309 7.309 0 0 1-.222-1.79V69.675h26.318c2.907 0 5.25 2.448 5.25 5.42v.04c0 2.971 2.37 5.37 5.277 5.37h34.785c2.907 0 5.277-2.421 5.277-5.393V75.1c0-2.972 2.343-5.426 5.25-5.426h26.318v33.569c0 .617-.077 1.216-.221 1.789z"
                            />
                        </g>
                        <path
                            className="ant-empty-img-3"
                            d="M149.121 33.292l-6.83 2.65a1 1 0 0 1-1.317-1.23l1.937-6.207c-2.589-2.944-4.109-6.534-4.109-10.408C138.802 8.102 148.92 0 161.402 0 173.881 0 184 8.102 184 18.097c0 9.995-10.118 18.097-22.599 18.097-4.528 0-8.744-1.066-12.28-2.902z"
                        />
                        <g className="ant-empty-img-4" transform="translate(149.65 15.383)">
                            <ellipse cx="20.654" cy="3.167" rx="2.849" ry="2.815" />
                            <path d="M5.698 5.63H0L2.898.704zM9.259.704h4.985V5.63H9.259z" />
                        </g>
                    </g>
                </svg>
                <Box sx={{ mt: 1 }}>No Rows</Box>
            </StyledGridOverlay>
        );
    }

    return (
        <Fragment>
            <div style={{ height: 800, width: '100%' }}>
                <Button variant="contained" sx={{ my: 2 }} onClick={autoFloodQuoteExportFormat}>Flood Quote Fields</Button>
                <Button variant="contained" sx={{ m: 2 }} onClick={autoHomeQuoteExportFormat}>Home Quote Fields</Button>
                <DataGridPro
                    rows={rowsConstructed}
                    columns={columnsConstructed}
                    components={{
                        Toolbar: GridToolbar,
                        LoadingOverlay: CustomLoadingOverlay,
                        NoRowsOverlay: CustomNoRowsOverlay,
                    }}
                    getDetailPanelContent={getDetailPanelContent}
                    getDetailPanelHeight={({ row }) => 820}
                    loading={!!props.queryRequestLoading ? true : false}
                    columnVisibilityModel={columnVisibilityModel}
                    onColumnVisibilityModelChange={(newModel) =>
                        setColumnVisibilityModel(newModel)
                    }
                    density="compact"
                />
            </div>
        </Fragment >
    );
}

export default BulkQueryDataGrid;

