import { createContext, useState } from "react"

const AuthContext = createContext({
    token: '',
    isLoggedIn: false,
    login: (token) => { },
    logout: () => { },
});

function calculateRemainingTime(expirationTime) {
    const currentTime = new Date().getTime();
    const adjustedExpirationTime = new Date(expirationTime).getTime();

    const remainingDuration = adjustedExpirationTime - currentTime;

    return remainingDuration;
}

export const AuthContextProvider = (props) => {

    // Check for locally stored token
    const initialToken = localStorage.getItem('token');

    // Token State (initial either defined or null)
    const [token, setToken] = useState(initialToken);

    // Logged in boolean
    const userIsLoggedIn = !!token;

    const logoutHandler = () => {
        setToken(null);
        localStorage.removeItem('token'); // Remove local token
    };

    const loginHandler = (token, expirationTime) => {
        setToken(token);
        localStorage.setItem('token', token); // Save locally

        const remainingTime = calculateRemainingTime(expirationTime);

        // Remaining Time can be manually changed (miliseconds)
        // setTimeout(loginHandler, 600000); // Ten Minutes
        setTimeout(loginHandler, remainingTime); // DEBUG

    };

    const contextValue = {
        token: token,
        isLoggedIn: userIsLoggedIn,
        login: loginHandler,
        logout: logoutHandler
    };

    return <AuthContext.Provider value={contextValue}>
        {props.children}
    </AuthContext.Provider>
};

export default AuthContext;