import { useEffect, useContext, useState } from "react";
import { Container, Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";

import AuthContext from "../store/auth-context";

function FourOFour() {

  const navigate = useNavigate();

  const authCtx = useContext(AuthContext);

  const [counter, setCounter] = useState(5);


  // Wait 5 seconds
  useEffect(() => {
    counter > 0 && setTimeout(() => setCounter(counter - 1), 1000);
    if (counter === 0) {
      // Reroute on login
      if (!!authCtx.isLoggedIn) {
        navigate('/bulkQuery');
      }
      else {
        navigate('/login');
      }
    }
  }, [counter]);



  return (
    <Container>
      <Grid>
        <h1>404</h1>
        <h3>Looks like we took a wrong turn</h3>
        Redirecting you automatically in {counter}
      </Grid>
    </Container>
  );
}

export default FourOFour;
