import './App.css';

// Default Fonts (MUI)
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import NavBar from './components/NavBar';
import { Outlet } from 'react-router-dom';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import AuthContext from './store/auth-context';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';

function App() {

  const authCtx = useContext(AuthContext);
  const navigate = useNavigate();

  // Reroute on login
  useEffect(() => {
    if (!!authCtx.isLoggedIn) {
      navigate('/bulkQuery');
      // navigate('/home');
    }
    else {
      navigate('/login');
    }
  }, [authCtx.isLoggedIn]);

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <CssBaseline />
      <Box className="App" sx={{ display: 'flex', flexDirection: 'column', height: '100vh' }}>
        <header>
          <NavBar sx={{ display: 'flex' }} />
        </header>
        {/* <body> */}
        <Box sx={{ display: 'flex', flexGrow: 1, position: 'relative' }}>
          <Outlet />
        </Box>
        {/* </body> */}
      </Box>
    </LocalizationProvider>
  );
}

export default App;
