import * as React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import Grid from '@mui/material/Grid';
import { Box, Typography } from '@mui/material';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { TextField } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import { FilledInput } from '@mui/material';
import DatePicker from '@mui/lab/DatePicker';
import { Checkbox } from '@mui/material';
import { Autocomplete } from '@mui/material';

import VirtualizedAutoComplete from './Search_MultiSelectVirtual';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export default function Filter(props) {

    // Values from the form! 😛
    const [values, setValues] = useState({
        coastalDistance: ['', '', ''],
        yearOfConstruction: [null, null, null],
        squareFootage: ['', '', ''],
        replacementCost: ['', '', ''],
        purchaseDate: [null, null, null],
        residency: [],
        families: [],
        excludeOwnerType: '',
        ownerOutOfState: true,
        useCode_CAMA: ['101'],
        floodZone: [],
        bookOfBusiness: false,
    });

    // Put props function in new variable to avoid using props as a dependancy in useEffect
    const update_queryRequest_props = props.update_queryRequest;
    // Update the queryRequest
    useEffect(() => {
        update_queryRequest_props(values, 'filter');
    }, [values, update_queryRequest_props]);

    // Update values onchange
    const handleChange = (prop, index, value = null) => (event) => {
        // Make a copy of the state
        let intermediate = values;

        if (!index) {
            // Update the value using the prop and index
            intermediate[prop][0] = event.target.value;
        } else {
            // Update the value using the prop and index
            intermediate[prop][index] = event.target.value;
        }
        // Define the prop (with its index's)
        const udpateMe = intermediate[prop];
        // Reset the state
        setValues({ ...values, [prop]: udpateMe });
        // console.log('values: ', values);
    };

    // CHECKBOX CHANGE
    const handleCheckboxChange = (prop) => (event) => {
        // Make a copy of the state
        let intermediate = values;
        // Update the value using the prop and index
        intermediate[prop] = event.target.checked;
        // Define the prop (with its index's)
        const udpateMe = intermediate[prop];
        // Reset the state
        setValues({ ...values, [prop]: udpateMe });
        // console.log('values: ', values);
    }

    // DATE CHANGE
    const handleDateChange = (prop, index, value) => {
        // console.log('value: ', value); // DEBUG
        // Make a copy of the state
        const intermediate = values;
        // Update the value using the prop and index
        intermediate[prop][index] = value;
        // Define the prop (with its index's)
        const udpateMe = intermediate[prop];
        // Reset the state
        setValues({ ...values, [prop]: udpateMe });
        //console.log('values: ', values); // DEBUB
    }

    // AUTO-COMPELTE CHANGE
    const handleAutoCompleteChange = (prop) => (event, value) => {
        // Make a copy of the state
        let intermediate = values;
        // Update the value using the prop and index
        intermediate[prop] = value;
        // Reset the state
        setValues({ ...values, [prop]: value });
        //// console.log('values: ', values); // DEBUG
    };

    //// console.log(props.useCodes); // DEBUG

    return (
        <Grid container spacing={2}>
            {/* TILE */}
            <Grid item sx={{ width: '100%' }}>
                <Box sx={{ display: 'flex', bgcolor: 'background.paper', borderRadius: 1 }} >
                    <Box sx={{ minWidth: '175px' }}></Box>
                    <Grid container spacing={2}>
                        <Grid item xs={4}>
                            <Typography label="Specific" size='small' sx={{ width: '100%' }}>Specific </Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <Typography label="Specific" size='small'>Minimum </Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <Typography label="Specific" size='small'>Maximum </Typography>
                        </Grid>
                    </Grid>
                </Box>
            </Grid>
            {/* COASTAL DISTANCE */}
            <Grid item sx={{ width: '100%' }}>
                <Box sx={{ display: 'flex', bgcolor: 'background.paper', borderRadius: 1 }} >
                    <Box sx={{ display: 'flex', minWidth: '175px', alignSelf: 'center' }}>Coastal Distance</Box>
                    <Grid container spacing={2}>
                        <Grid item xs={4}>
                            <FilledInput
                                disabled
                                id="coastalDistance_specific"
                                error={values['coastalDistance'][0] !== "" && (values['coastalDistance'][1] !== "" || values['coastalDistance'][2]) !== "" ? true : false}
                                value={values['coastalDistance'][0]}
                                onChange={handleChange('coastalDistance', 0)}
                                endAdornment={<InputAdornment position="end">miles</InputAdornment>}
                                inputProps={{
                                    'aria-label': 'weight',
                                }}
                                sx={{ width: '100%' }}
                                size='small'
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <FilledInput
                                id="coastalDistance_min"
                                error={values['coastalDistance'][0] !== "" && (values['coastalDistance'][1] !== "" || values['coastalDistance'][2]) !== "" ? true : false}
                                value={values.coastalDistance[1]}
                                onChange={handleChange('coastalDistance', 1)}
                                endAdornment={<InputAdornment position="end">miles</InputAdornment>}
                                aria-describedby="filled-weight-helper-text"
                                inputProps={{
                                    'aria-label': 'weight',
                                }}
                                sx={{ width: '100%' }}
                                size='small'
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <FilledInput
                                id="coastalDistance_max"
                                error={values['coastalDistance'][0] !== "" && (values['coastalDistance'][1] !== "" || values['coastalDistance'][2]) !== "" ? true : false}
                                value={values.coastalDistance[2]}
                                onChange={handleChange('coastalDistance', 2)}
                                endAdornment={<InputAdornment position="end">miles</InputAdornment>}
                                aria-describedby="filled-weight-helper-text"
                                inputProps={{
                                    'aria-label': 'weight',
                                }}
                                sx={{ width: '100%' }}
                                size='small'
                            />
                        </Grid>
                    </Grid>
                </Box>
            </Grid>
            {/* YEAR OF CONSTRUCTION */}
            <Grid item sx={{ width: '100%' }}>
                <Box sx={{ display: 'flex', bgcolor: 'background.paper', borderRadius: 1 }} >
                    <Box sx={{ display: 'flex', minWidth: '175px', alignSelf: 'center' }}>Year of Construction</Box>
                    <Grid container spacing={2}>
                        <Grid item xs={4}>
                            <DatePicker
                                views={['year']}
                                label=""
                                value={values['yearOfConstruction'][0]}
                                maxDate={new Date()}
                                onChange={(newValue) => {
                                    // console.log(newValue.getFullYear()); // DEBUB
                                    handleDateChange('yearOfConstruction', 0, newValue);
                                }}
                                renderInput={(params) => <TextField
                                    {...params}
                                    error={values['yearOfConstruction'][0] !== null && (values['yearOfConstruction'][1] !== null || values['yearOfConstruction'][2]) !== null ? true : false}
                                    size='small'
                                    sx={{ width: '100%' }}
                                    variant="filled"
                                    helperText={null}
                                />}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <DatePicker
                                views={['year']}
                                label=""
                                value={values['yearOfConstruction'][1]}
                                maxDate={new Date()}
                                onChange={(newValue) => {
                                    handleDateChange('yearOfConstruction', 1, newValue);
                                }}
                                renderInput={(params) => <TextField
                                    {...params}
                                    error={values['yearOfConstruction'][0] !== null && (values['yearOfConstruction'][1] !== null || values['yearOfConstruction'][2]) !== null ? true : false}
                                    size='small'
                                    sx={{ width: '100%' }}
                                    variant="filled"
                                    helperText={null}
                                />}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <DatePicker
                                views={['year']}
                                label=""
                                value={values['yearOfConstruction'][2]}
                                maxDate={new Date()}
                                onChange={(newValue) => {
                                    handleDateChange('yearOfConstruction', 2, newValue);
                                }}
                                renderInput={(params) => <TextField
                                    {...params}
                                    error={values['yearOfConstruction'][0] !== null && (values['yearOfConstruction'][1] !== null || values['yearOfConstruction'][2]) !== null ? true : false}
                                    size='small'
                                    sx={{ width: '100%' }}
                                    variant="filled"
                                    helperText={null}
                                />}
                            />
                        </Grid>
                    </Grid>
                </Box>
            </Grid>
            {/* SQUARE FOOTAGE */}
            <Grid item sx={{ width: '100%' }}>
                <Box sx={{ display: 'flex', bgcolor: 'background.paper', borderRadius: 1 }} >
                    <Box sx={{ display: 'flex', minWidth: '175px', alignSelf: 'center' }}>Square Footage</Box>
                    <Grid container spacing={2}>
                        <Grid item xs={4}>
                            <FilledInput
                                id="squareFootage_specific"
                                error={values['squareFootage'][0] !== "" && (values['squareFootage'][1] !== "" || values['squareFootage'][2]) !== "" ? true : false}
                                value={values['squareFootage'][0]}
                                onChange={handleChange('squareFootage', 0)}
                                endAdornment={<InputAdornment position="end">sq/ft</InputAdornment>}
                                inputProps={{
                                    'aria-label': 'weight',
                                }}
                                sx={{ width: '100%' }}
                                size='small'
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <FilledInput
                                id="squareFootage_min"
                                error={values['squareFootage'][0] !== "" && (values['squareFootage'][1] !== "" || values['squareFootage'][2]) !== "" ? true : false}
                                value={values.squareFootage[1]}
                                onChange={handleChange('squareFootage', 1)}
                                endAdornment={<InputAdornment position="end">sq/ft</InputAdornment>}
                                aria-describedby="filled-weight-helper-text"
                                inputProps={{
                                    'aria-label': 'weight',
                                }}
                                sx={{ width: '100%' }}
                                size='small'
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <FilledInput
                                id="squareFootage_max"
                                error={values['squareFootage'][0] !== "" && (values['squareFootage'][1] !== "" || values['squareFootage'][2]) !== "" ? true : false}
                                value={values.squareFootage[2]}
                                onChange={handleChange('squareFootage', 2)}
                                endAdornment={<InputAdornment position="end">sq/ft</InputAdornment>}
                                aria-describedby="filled-weight-helper-text"
                                inputProps={{
                                    'aria-label': 'weight',
                                }}
                                sx={{ width: '100%' }}
                                size='small'
                            />
                        </Grid>
                    </Grid>
                </Box>
            </Grid>
            {/* REPLACEMENT COST */}
            <Grid item sx={{ width: '100%' }}>
                <Box sx={{ display: 'flex', bgcolor: 'background.paper', borderRadius: 1 }} >
                    <Box sx={{ display: 'flex', minWidth: '175px', alignSelf: 'center' }}>Replacement Cost</Box>
                    <Grid container spacing={2}>
                        <Grid item xs={4}>
                            <FilledInput
                                disabled
                                id="replacementCost_specific"
                                error={values['replacementCost'][0] !== "" && (values['replacementCost'][1] !== "" || values['replacementCost'][2]) !== "" ? true : false}
                                value={values['replacementCost'][0]}
                                onChange={handleChange('replacementCost', 0)}
                                startAdornment={<InputAdornment position="start">$</InputAdornment>}
                                inputProps={{
                                    'aria-label': 'weight',
                                }}
                                sx={{ width: '100%' }}
                                size='small'
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <FilledInput
                                disabled
                                id="replacementCost_min"
                                error={values['replacementCost'][0] !== "" && (values['replacementCost'][1] !== "" || values['replacementCost'][2]) !== "" ? true : false}
                                value={values.replacementCost[1]}
                                onChange={handleChange('replacementCost', 1)}
                                startAdornment={<InputAdornment position="start">$</InputAdornment>}
                                aria-describedby="filled-weight-helper-text"
                                inputProps={{
                                    'aria-label': 'weight',
                                }}
                                sx={{ width: '100%' }}
                                size='small'
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <FilledInput
                                disabled
                                id="replacementCost_max"
                                error={values['replacementCost'][0] !== "" && (values['replacementCost'][1] !== "" || values['replacementCost'][2]) !== "" ? true : false}
                                value={values.replacementCost[2]}
                                onChange={handleChange('replacementCost', 2)}
                                startAdornment={<InputAdornment position="start">$</InputAdornment>}
                                aria-describedby="filled-weight-helper-text"
                                inputProps={{
                                    'aria-label': 'weight',
                                }}
                                sx={{ width: '100%' }}
                                size='small'
                            />
                        </Grid>
                    </Grid>
                </Box>
            </Grid>
            {/* PURCHASE DATE */}
            <Grid item sx={{ width: '100%' }}>
                <Box sx={{ display: 'flex', bgcolor: 'background.paper', borderRadius: 1 }} >
                    <Box sx={{ display: 'flex', minWidth: '175px', alignSelf: 'center' }}>Purchase Date</Box>
                    <Grid container spacing={2}>
                        <Grid item xs={4}>
                            <DatePicker
                                views={['day', 'month', 'year']}
                                label=""
                                value={values['purchaseDate'][0]}
                                // maxDate={new Date()}
                                onChange={(newValue) => {
                                    // console.log(newValue.getFullYear()); // DEBUB
                                    handleDateChange('purchaseDate', 0, newValue);
                                }}
                                renderInput={(params) => <TextField
                                    {...params}
                                    error={values['purchaseDate'][0] !== null && (values['purchaseDate'][1] !== null || values['purchaseDate'][2]) !== null ? true : false}
                                    size='small'
                                    sx={{ width: '100%' }}
                                    variant="filled"
                                    helperText={null}
                                />}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <DatePicker
                                views={['day', 'month', 'year']}
                                label=""
                                value={values['purchaseDate'][1]}
                                // maxDate={new Date()}
                                onChange={(newValue) => {
                                    handleDateChange('purchaseDate', 1, newValue);
                                }}
                                renderInput={(params) => <TextField
                                    {...params}
                                    error={values['purchaseDate'][0] !== null && (values['purchaseDate'][1] !== null || values['purchaseDate'][2]) !== null ? true : false}
                                    size='small'
                                    sx={{ width: '100%' }}
                                    variant="filled"
                                    helperText={null}
                                />}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <DatePicker
                                views={['day', 'month', 'year']}
                                label=""
                                value={values['purchaseDate'][2]}
                                // maxDate={new Date()}
                                onChange={(newValue) => {
                                    handleDateChange('purchaseDate', 2, newValue);
                                }}
                                renderInput={(params) => <TextField
                                    {...params}
                                    error={values['purchaseDate'][0] !== null && (values['purchaseDate'][1] !== null || values['purchaseDate'][2]) !== null ? true : false}
                                    size='small'
                                    sx={{ width: '100%' }}
                                    variant="filled"
                                    helperText={null}
                                />}
                            />
                        </Grid>
                    </Grid>
                </Box>
            </Grid>
            {/* Residency */}
            <Grid item sx={{ width: '100%' }}>
                <Box sx={{ display: 'flex', bgcolor: 'background.paper', borderRadius: 1 }} >
                    <Box sx={{ display: 'flex', minWidth: '175px', alignSelf: 'center' }}>Residency</Box>
                    <Grid container spacing={2}>
                        <Grid item xs={8}>
                            <Autocomplete
                                disabled
                                value={values['residency']}
                                onChange={handleAutoCompleteChange('residency')}
                                multiple
                                options={['Primary', 'Secondary']}
                                disableCloseOnSelect
                                loading={!props.county ? true : false}
                                loadingText='...'
                                sx={{ width: '100%' }}
                                size='small'
                                variant="filled"
                                getOptionLabel={(option) => option}
                                renderOption={(props, option, { selected }) => (
                                    <li {...props}>
                                        <Checkbox
                                            icon={icon}
                                            checkedIcon={checkedIcon}
                                            style={{ marginRight: 8 }}
                                            checked={selected}
                                        />
                                        {option}
                                    </li>
                                )}
                                renderInput={(params) => (
                                    <TextField {...params} label="Residency" placeholder="" />
                                )}
                            />
                        </Grid>
                    </Grid>
                </Box>
            </Grid>
            {/* Use Code */}
            <Grid item sx={{ width: '100%' }}>
                <Box sx={{ display: 'flex', bgcolor: 'background.paper', borderRadius: 1 }} >
                    <Box sx={{ display: 'flex', minWidth: '175px', alignSelf: 'center' }}>Use Code</Box>
                    <Grid container spacing={2}>
                        <Grid item xs={8}>
                            <VirtualizedAutoComplete defaultValue={['101']} options={props.useCodes} name={'useCode_CAMA'} optionValueName='full_code' optionRender='description' optionIndex='major_div' values={values['useCode_CAMA']} onChange={handleAutoCompleteChange} label={'Use Code'} />
                        </Grid>
                    </Grid>
                </Box>
            </Grid>
            {/* Flood Zone */}
            <Grid item sx={{ width: '100%' }}>
                <Box sx={{ display: 'flex', bgcolor: 'background.paper', borderRadius: 1 }} >
                    <Box sx={{ display: 'flex', minWidth: '175px', alignSelf: 'center' }}>Flood Zone</Box>
                    <Grid container spacing={2}>
                        <Grid item xs={8}>
                            <Autocomplete
                                // disabled
                                value={values['floodZone']}
                                onChange={handleAutoCompleteChange('floodZone')}
                                multiple
                                options={['X', 'D', 'VE', 'Area Not Included', 'AO', 'AH', 'AE', 'A',].sort()}
                                disableCloseOnSelect
                                loading={!props.county ? true : false}
                                loadingText='...'
                                sx={{ width: '100%' }}
                                size='small'
                                variant="filled"
                                getOptionLabel={(option) => option}
                                renderOption={(props, option, { selected }) => (
                                    <li {...props}>
                                        <Checkbox
                                            icon={icon}
                                            checkedIcon={checkedIcon}
                                            style={{ marginRight: 8 }}
                                            checked={selected}
                                        />
                                        {option}
                                    </li>
                                )}
                                renderInput={(params) => (
                                    <TextField {...params} label="Flood Zone" placeholder="" />
                                )}
                            />
                        </Grid>
                    </Grid>
                </Box>
            </Grid>
            {/* Families */}
            <Grid item sx={{ width: '100%' }}>
                <Box sx={{ display: 'flex', bgcolor: 'background.paper', borderRadius: 1 }} >
                    <Box sx={{ display: 'flex', minWidth: '175px', alignSelf: 'center' }}>Families</Box>
                    <Grid container spacing={2}>
                        <Grid item xs={8}>
                            <Autocomplete
                                disabled
                                value={values['families']}
                                onChange={handleAutoCompleteChange('families')}
                                multiple
                                options={['1', '2', '3']}
                                disableCloseOnSelect
                                loading={!props.county ? true : false}
                                loadingText='...'
                                sx={{ width: '100%' }}
                                size='small'
                                variant="filled"
                                getOptionLabel={(option) => option}
                                renderOption={(props, option, { selected }) => (
                                    <li {...props}>
                                        <Checkbox
                                            icon={icon}
                                            checkedIcon={checkedIcon}
                                            style={{ marginRight: 8 }}
                                            checked={selected}
                                        />
                                        {option}
                                    </li>
                                )}
                                renderInput={(params) => (
                                    <TextField {...params} label="Families" placeholder="" />
                                )}
                            />
                        </Grid>
                    </Grid>
                </Box>
            </Grid>
            {/* Owner Out Of State */}
            <Grid item sx={{ width: '100%' }}>
                <Box sx={{ display: 'flex', bgcolor: 'background.paper', borderRadius: 1 }} >
                    <Box sx={{ display: 'flex', minWidth: '175px', alignSelf: 'center' }}>Include Owner Out of State</Box>
                    <Grid container spacing={2}>
                        <Grid item xs={8}>
                            <Checkbox
                                checked={values['ownerOutOfState']}
                                onChange={handleCheckboxChange('ownerOutOfState')}
                                inputProps={{ 'aria-label': 'controlled' }}
                                disabled
                            />
                        </Grid>
                    </Grid>
                </Box>
            </Grid>
            {/* Book Of Business */}
            <Grid item sx={{ width: '100%' }}>
                <Box sx={{ display: 'flex', bgcolor: 'background.paper', borderRadius: 1 }} >
                    <Box sx={{ display: 'flex', minWidth: '175px', alignSelf: 'center' }}>Include Book Of Business</Box>
                    <Grid container spacing={2}>
                        <Grid item xs={8}>
                            <Checkbox
                                checked={values['bookOfBusiness']}
                                onChange={handleCheckboxChange('bookOfBusiness')}
                                inputProps={{ 'aria-label': 'controlled' }}
                                // disabled
                            />
                        </Grid>
                    </Grid>
                </Box>
            </Grid>
        </Grid >
    );
}
