import * as React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { styled } from '@mui/material/styles';

import { currencyUSD } from '../../utilities/utilities';

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(even)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

function createData(id, coverage, value) {
    let details = 'yes';
    switch (id) {
        case 'A':
            details = {
                description: "This overage can help you pay to repair or rebuild your home if it is damaged by a covered event. Typically, you will want to have enough dwelling coverage to cover the estimated cost to repair, rebuild and replace your property."
            }
            break;
        case 'B':
            details = {
                description: 'This coverage can help pay for covered damages to detached structures on your property like garages, sheds and fences.'
            }
            break;
        case 'C':
            details = {
                description: 'This coverage can help you pay for the personal items in your home that may be damaged or destroyed by a covered cause of loss, which could include your furniture, clothes, sporting goods and electronics. Special limits of liability may apply to certain items.'
            }
            break;
        case 'D':
            details = {
                description: "This coverage can help pay your additional living expenses if you must move out of your home temporarily while it's being restored. This is typically subject to coverage terms and limits."
            }
            break;
        case 'E':
            details = {
                description: 'This coverage can help protect you in the event of a claim. It also helps cover related costs if a legal suit is brought against you or a member of your household for bodily injury or property damage to others.'
            }
            break;
        case 'F':
            details = {
                description: "This coverage can help to pay for expenses related to an injury that occurs on your property — whether you've been found liable or not. It works by reimbursing the policyholder for expenses that have been paid out to things like medical bills and funeral costs that are derived from the injury."
            }
            break;
        case 'AOP':
            details = {
                description: "An insurance deductible is the amount of a covered claim that is your responsibility before the insurance coverage takes over."
            }
            break;
        case 'ALO':
            details = {
                description: "A separate, higher deductible provision that applies to loss caused by wind or hail. Often, the deductible is expressed as a percentage of the value of the property or, in a homeowners policy, as a percentage of the dwelling limit, rather than as a flat dollar amount."
            }
            break;
        default:
            details = {
                description: ""
            }
            break;
    }
    return {
        id,
        coverage,
        value,
        details
    };
}

function Row(props) {
    const { row } = props;
    const [open, setOpen] = React.useState(false);

    return (
        <React.Fragment>
            <StyledTableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
                {/* <TableCell style={{ maxWidth: 10 }}>
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => setOpen(!open)}
                    >
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell> */}
                <TableCell align="left" sx={{ cursor: 'pointer' }} onClick={() => setOpen(!open)}>{row.coverage}</TableCell>
                <TableCell align="right">{row.value}</TableCell>
            </StyledTableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0, maxWidth: 100 }} colSpan={6}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box sx={{ margin: 1 }}>
                            <p>{row.details.description}</p>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
}

export default function CoveragesTable(props) {

    const rows = [
        createData('A', 'Building', currencyUSD(props.coverages * 250)),
        createData('B', 'Other Structures', currencyUSD((props.coverages * 250) / 10)),
        createData('C', 'Contents', currencyUSD((props.coverages * 250) / 2)),
        createData('D', 'Loss of Use', currencyUSD(Math.round((props.coverages * 250) / 3))),
        createData('E', 'Personal Liability', currencyUSD(500000)),
        createData('F', 'Medical Payments', currencyUSD(5000)),
        createData('AOP', 'Deductible', currencyUSD(1000)),
        createData('ALO', 'Seperate Deductible (wind/hurricane/etc.)', currencyUSD(5000)),
    ];
    return (
        <TableContainer >
            <Table aria-label="collapsible table" size={'small'}>
                {/* <TableHead>
                    <TableRow>
                        <TableCell>Coverage</TableCell>
                        <TableCell ></TableCell>
                    </TableRow>
                </TableHead> */}
                <TableBody>
                    {rows.map((row) => (
                        <Row key={row.id} row={row} />
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}
