import { Container, Paper } from "@mui/material";
import { Box } from "@mui/system";
import Controls from "../components/bulkQuery/Controls"
import { Grid } from "@mui/material";
import BulkQueryDataGrid from "../components/bulkQuery/bulkQueryDataGrid/BulkQueryDataGrid";

import BulkQueryComponent from '../components/bulkQuery/BulkQueryComponent'
import { useEffect, useState } from "react";

function BulkQuery() {

  const [queryResponse, setQueryResponse] = useState(null);
  useEffect(() => {
    console.log('queryResponse: ', queryResponse);
  }, [queryResponse]);

  const [queryRequest, setQueryRequest] = useState({ filter: null });
  const [queryRequestLoading, setQueryRequestLoading] = useState(false);

  const update_queryRequest = (payload, index, field) => {

    if (!payload || !index) {
      return
    }

    // Make a copy of the state
    let intermediate = queryRequest;

    //// console.log('New Array: ', intermediate); // DEBUG

    if (!field) {
      //// console.log('!field'); // DEBUG
      // Update the value using the prop and index
      intermediate[index] = payload;
    } else {
      //// console.log('!!field'); // DEBUGG
      // Update the value using the prop and index
      intermediate[index][field] = payload;
    }
    // Reset the state
    setQueryRequest(intermediate);
    //// console.log('New Array: ', intermediate); // DEBUG
  }

  return (
    <Box>
      <Container sx={{ mb: 16 }}>
        <Grid container spacing={2}>
          <Grid item>
            <Paper variant="outlined" sx={{ p: 2, mt: 2 }}>
              <Grid container spacing={2}>
                {/* FORM */}
                <Grid item>
                  <BulkQueryComponent update_queryRequest={update_queryRequest} queryRequest={queryRequest} />
                </Grid>
                {/* CONTROLS */}
                <Grid item>
                  <Controls queryRequest={queryRequest} setQueryResponse={setQueryResponse} queryRequestLoading={queryRequestLoading} setQueryRequestLoading={setQueryRequestLoading} />
                </Grid>
              </Grid>
            </Paper>
          </Grid>
          <Grid item sx={{ width: '100%' }}>
            <BulkQueryDataGrid queryResponse={queryResponse} queryRequest={queryRequest} queryRequestLoading={queryRequestLoading} />
          </Grid>
        </Grid>
      </Container>
    </Box >
  );
}

export default BulkQuery;
