import * as React from 'react';
import { useState, useEffect } from 'react';
import { toTitleCase } from '../../../utilities/utilities';

import Grid from '@mui/material/Grid';
import { Box } from '@mui/material';
import { Checkbox } from '@mui/material';
import { Autocomplete } from '@mui/material';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { TextField } from '@mui/material';
import { FilledInput } from '@mui/material';

import VirtualizedAutoComplete from './Search_MultiSelectVirtual';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export default function Search(props) {

    // Values from the form! 😛
    const [values, setValues] = useState({
        state: false,
        county: [],
        townCity: ['MATTAPOISETT'],
        limit: ''
    });

    // Put props function in new variable to avoid using props as a dependancy in useEffect
    const update_queryRequest_props = props.update_queryRequest;
    // Update the queryRequest
    useEffect(() => {
        update_queryRequest_props(values, 'search');
    }, [values, update_queryRequest_props]);


    // CHECKBOX CHANGE
    const handleCheckboxChange = (prop) => (event) => {
        // Make a copy of the state
        let intermediate = values;
        // Update the value using the prop and index
        intermediate[prop] = event.target.checked;
        // Define the prop (with its index's)
        const udpateMe = intermediate[prop];
        // Reset the state
        setValues({ ...values, [prop]: udpateMe });
        // console.log('values: ', values);
    }

    // AUTO-COMPELTE CHANGE
    const handleAutoCompleteChange = (prop) => (event, value) => {
        // Make a copy of the state
        let intermediate = values;
        // Update the value using the prop and index
        intermediate[prop] = value;
        // Reset the state
        setValues({ ...values, [prop]: value });
        console.log('values: ', values); // DEBUG
    };

    // Update values onchange
    const handleChange = (prop, index, value = null) => (event) => {
        // Make a copy of the state
        let intermediate = values;

        intermediate[prop] = event.target.value;

        // Define the prop (with its index's)
        const udpateMe = intermediate[prop];
        // Reset the state
        setValues({ ...values, [prop]: udpateMe });
        // console.log('values: ', values);
    };

    return (
        <Grid container spacing={2}>
            <Grid item sx={{ width: '100%' }}>
                <Box sx={{ display: 'flex', bgcolor: 'background.paper', borderRadius: 1 }} >
                    <Box sx={{ display: 'flex', minWidth: '175px', alignSelf: 'center' }}>Entire State</Box>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Checkbox
                                checked={values['state']}
                                onChange={handleCheckboxChange('state')}
                                inputProps={{ 'aria-label': 'controlled' }}
                                disabled
                            />
                        </Grid>
                    </Grid>
                </Box>
            </Grid>
            <Grid item sx={{ width: '100%' }}>
                <Box sx={{ display: 'flex', bgcolor: 'background.paper', borderRadius: 1 }} >
                    <Box sx={{ display: 'flex', minWidth: '175px', alignSelf: 'center' }}>County</Box>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Autocomplete
                                multiple
                                value={values['county']}
                                onChange={handleAutoCompleteChange('county')}
                                options={props.county}
                                disableCloseOnSelect
                                loading={!props.county ? true : false}
                                loadingText='...'
                                sx={{ width: '100%' }}
                                size='small'
                                variant="filled"
                                getOptionLabel={(option) => option}
                                renderOption={(props, option, { selected }) => (
                                    <li {...props}>
                                        <Checkbox
                                            icon={icon}
                                            checkedIcon={checkedIcon}
                                            style={{ marginRight: 8 }}
                                            checked={selected}
                                        />
                                        {option}
                                    </li>
                                )}
                                renderInput={(params) => (
                                    <TextField {...params} label="County" placeholder="" />
                                )}
                            />
                        </Grid>
                    </Grid>
                </Box>
            </Grid>
            {/* Town/City */}
            <Grid item sx={{ width: '100%' }}>
                <Box sx={{ display: 'flex', bgcolor: 'background.paper', borderRadius: 1 }} >
                    <Box sx={{ display: 'flex', minWidth: '175px', alignSelf: 'center' }}>Town/City</Box>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <VirtualizedAutoComplete
                                options={props.townCity}
                                // getOptionLabel={(option) => option.label}
                                defaultValue={['MATTAPOISETT']}
                                name={'townCity'}
                                values={values['townCity']}
                                onChange={handleAutoCompleteChange}
                                label={'Town/City'} />
                        </Grid>
                    </Grid>
                </Box>
            </Grid>
            {/* Limit */}
            <Grid item sx={{ width: '100%' }}>
                <Box sx={{ display: 'flex', bgcolor: 'background.paper', borderRadius: 1 }} >
                    <Box sx={{ display: 'flex', minWidth: '175px', alignSelf: 'center' }}>Limit</Box>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <FilledInput
                                id="replacementCost_request"
                                error={values['limit'] > 150000}
                                value={values['limit']}
                                onChange={handleChange('limit')}
                                inputProps={{
                                    'aria-label': 'weight',
                                }}
                                sx={{ width: '100%' }}
                                size='small'
                            />
                        </Grid>
                    </Grid>
                </Box>
            </Grid>
        </Grid>
    );
}
