import { useState, useEffect, Fragment } from "react";
import { Container, Paper, Grid } from "@mui/material";

import MapApp from "../components/map/MapApp";

function Home() {

  // // Handle Drawer State
  // const [drawerOpen, setDrawerOpen] = useState(false);
  // const toggleDrawer = () => {
  //   setDrawerOpen(!drawerOpen);
  // };


  return (
      <MapApp />
  );
}

export default Home;
