import { useState, useContext, useEffect } from "react";
import { Container, Grid, Button, Avatar, CssBaseline, TextField, FormControlLabel, Checkbox, Link, Box, Typography } from "@mui/material";
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { treeViewClasses } from "@mui/lab";
import { useNavigate } from "react-router-dom";

import AuthContext from "../store/auth-context";

function Copyright(props) {
    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props}>
            {'Copyright © '}
            <Link color="inherit" href="https://testola.com/">
                Testola.com
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

export default function Login() {

    const authCtx = useContext(AuthContext);

    const [isLoading, setIsLoading] = useState(false);

    const navigate = useNavigate();

    const handleSubmit = (event) => {
        event.preventDefault();

        // Set isLoading
        setIsLoading(true);

        const data = new FormData(event.currentTarget);
        // eslint-disable-next-line no-console
        // console.log({
        //     email: data.get('email'),
        //     password: data.get('password'),
        // });
        // Login
        fetch('https://identitytoolkit.googleapis.com/v1/accounts:signInWithPassword?key=AIzaSyCc29mro1JawxnqtEFx8SHa_nCcr6TIjeQ',
            {
                method: 'POST',
                body: JSON.stringify({
                    email: data.get('email'),
                    password: data.get('password'),
                    returnSecureToken: true
                }),
                headers: {
                    'Content-Type': 'application/json'
                }
            }
        ).then(response => {
            // Set isLoading
            setIsLoading(false);

            if (response.ok) {
                return response.json();
            } else {
                response.json().then(data => {
                    let errorMessage = 'Authenticaion Failed!';
                    // Error 
                    // TODO COMMENT OUT/REMOVE
                    alert(errorMessage);
                    throw new Error(errorMessage);
                    // console.log(data);
                });
            }
        }).then((data) => {
            // Authenticated
            const expirationTime = new Date(
                new Date().getTime() + (+data.expiresIn * 1000)
            );
            authCtx.login(data.idToken, expirationTime.toISOString());
            // console.log(data);

        }).catch((err) => {
            alert(err.message)
        });
    };

    // Reroute on login
    useEffect(() => {
        if (!!authCtx.isLoggedIn) {
            navigate('/bulkQuery');
        }
        else {
            navigate('/login');
        }
    }, [authCtx.isLoggedIn]);


    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <Box
                sx={{
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                    <LockOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                    Sign in
                </Typography>
                <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label="Email Address"
                        name="email"
                        autoComplete="email"
                        autoFocus
                    />
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="Password"
                        type="password"
                        id="password"
                        autoComplete="current-password"
                    />
                    {/* <FormControlLabel
                        control={<Checkbox value="remember" color="primary" />}
                        label="Remember me"
                    /> */}
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                        disabled={!!isLoading ? true : false}
                    >
                        Sign In
                    </Button>
                    {/* <Grid container>
                        <Grid item xs>
                            <Link href="#" variant="body2">
                                Forgot password?
                            </Link>
                        </Grid>
                        <Grid item>
                            <Link href="#" variant="body2">
                                {"Don't have an account? Sign Up"}
                            </Link>
                        </Grid>
                    </Grid> */}
                </Box>
            </Box>
            <Copyright sx={{ mt: 8, mb: 4 }} />
        </Container>
    );
}
